import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldComponent } from './minicomponents/FieldComponent';

interface ManualModeComponentProps {
  manualDescription: string;
  onManualInputDescriptionChangeHandler: (newSummary: string) => void;
  getCreativityAndOutputLanguageSection: () => React.ReactNode;
  error: string;
}

export const ManualModeComponent = ({ manualDescription, onManualInputDescriptionChangeHandler, getCreativityAndOutputLanguageSection, error }: ManualModeComponentProps) => {
  const { t } = useTranslation();

  return (
    <Grid container size={12} gap={1}>
      <Grid size={12} textAlign={'left'}>
        <Typography variant={'body1'}>{t('app.labels.manualInputDescription')}</Typography>
      </Grid>
      <Grid size={12}>
        <FieldComponent fieldLabel={t('app.labels.descriptionFieldLabel')} fieldValue={manualDescription} onEdit={onManualInputDescriptionChangeHandler} error={error} />
      </Grid>
      <Grid size={12} container>
        {getCreativityAndOutputLanguageSection()}
      </Grid>
    </Grid>
  );
};
