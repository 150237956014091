import { Close as CloseIcon } from '@mui/icons-material';
import { Fade, IconButton, Link, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid'; // Updated import
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setUpdatesBannerClicked, setUpdatesBannerClosed, useListenUserChanges } from '../../utils/db';
import { useFirebaseLoginState } from '../../utils/hooks';

export default function UpdatesBannerComponent({
  bannerName,
  redirectUrl,
  title,
  description,
  image,
}: {
  bannerName: string;
  redirectUrl?: string;
  title: string;
  description: string;
  image: React.ReactNode;
}) {
  const { userId } = useFirebaseLoginState();
  const { dbUser, loading } = useListenUserChanges(userId);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  // console.log(`[UpdatesBannerComponent] bannerName: ${bannerName}, isVisible: ${isVisible}, loading: ${loading}`);

  useEffect(() => {
    // wait until dbUser is loaded, until then it is not visible
    if (loading || !dbUser) {
      // console.log(`[UpdatesBannerComponent :: useEffect] loading... :: isVisible: false`);
      setIsVisible(false);
      return;
    }

    const flagsBanners = dbUser?.flags?.banners;
    // console.log(`[UpdatesBannerComponent :: useEffect] flagsBanners:`, flagsBanners);

    // if exists or undefined (was not created yet) → it is visible
    const isBannersObjectNotCreatedYet = flagsBanners === undefined;
    if (isBannersObjectNotCreatedYet) {
      // console.log(`[UpdatesBannerComponent :: useEffect] isBannersObjectNotCreatedYet :: isVisible: true`);
      setIsVisible(true);
      return;
    }

    // if exists → check if banner is closed
    const isBannersObjectExists = !!flagsBanners;
    if (isBannersObjectExists) {
      const isBannerVisibile = !flagsBanners[`${bannerName}_closed`];
      // console.log(`[UpdatesBannerComponent :: useEffect] isBannersObjectExists :: isVisible: ${isBannerVisibile}`);
      setIsVisible(isBannerVisibile);
    }
  }, [dbUser, loading, bannerName]);

  const handleClose = () => {
    setUpdatesBannerClosed(userId, bannerName);
    setIsVisible(false);
  };

  const handleRedirect = () => {
    if (redirectUrl) {
      setUpdatesBannerClicked(userId, bannerName);
      window.open(redirectUrl, '_blank', 'noopener,noreferrer');
    }
  };

  // Don't render anything while loading or if banner should be hidden
  if (!isVisible) {
    return null;
  }

  return (
    <Fade in={isVisible} timeout={800}>
      <Grid container size={8} spacing={2} p={2} mb={3} border={'1px solid ' + green[100]} borderRadius={2} mx="auto" textAlign="left" bgcolor={green[50]} direction={'row'} position="relative">
        <IconButton aria-label="close" size="small" style={{ position: 'absolute', top: 8, right: 8, color: grey[500] }} onClick={handleClose}>
          <CloseIcon fontSize="small" sx={{ '&:hover': { color: grey[700], bgcolor: grey[300] }, borderRadius: '50%', p: 0.5 }} />
        </IconButton>
        <Grid container direction="row">
          <Grid size={'auto'}>{image}</Grid>
          <Grid container direction="column" size={'grow'}>
            <Grid>
              <Typography variant="h6" color="#262A30" fontSize={18}>
                {title}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" color="#262A30" fontSize={15}>
                {description}
              </Typography>
            </Grid>
            {redirectUrl && (
              <Grid mt={1}>
                <Typography variant="body1" color="#262A30" fontSize={15}>
                  <Link
                    component="button"
                    onClick={handleRedirect}
                    underline="hover"
                    sx={{
                      color: green[800],
                      fontWeight: 500,
                      cursor: 'pointer',
                    }}>
                    {t('app.banners.learn_more')}
                  </Link>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
}
