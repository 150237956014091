import "firebase/compat/firestore";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { firebaseTimestampToDate } from "./dateUtils";
import { firestoreDb } from "./firebaseUtils";
import {
  Campaign,
  CodeDetails,
  Item,
  LoadingStatus,
  Plan,
  Product,
} from "./types";

const DB_PATH_USERS = "users";
const DB_PATH_CODES = "partners/appsumo/codes";
const DB_PATH_CAMPAIGNS = "campaigns";
const DB_PATH_PRODUCTS = "products";
const DB_PATH_ITEMS = "items";
const DB_PATH_PLANS = "plans/v2/items";

export const useListenUserChanges = (userId?: string) => {
  const [dbUser, setDbUser] = useState<DocumentData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      setDbUser(null);
      setError(new Error("No user ID provided"));
      return;
    }

    const userDocRef = doc(firestoreDb, DB_PATH_USERS, userId);

    const unsubscribe = onSnapshot(
      userDocRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setDbUser(snapshot.data());
        } else {
          setError(new Error("User document does not exist"));
        }
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    // Clean up subscription on unmount
    return () => {
      unsubscribe();
    };
  }, [userId]);

  return { dbUser, loading, error };
};

export const useUserCodes = (userId?: string): CodeDetails[] => {
  const [codes, setCodes] = useState<CodeDetails[]>([]);

  useEffect(() => {
    if (!userId) {
      setCodes([]); // Clears any existing codes if the userId is not set
      return; // Stop the effect if no userId is provided
    }

    // Reference to the user's codes collection in Firestore
    const codesCollectionRef = collection(firestoreDb, DB_PATH_CODES);

    // Create a query object for codes that belong to the user, filter for is_redeemed=true and is_refaunded=false
    const codesQuery = query(
      codesCollectionRef,
      where("user_id", "==", userId)
    );

    // Subscribe to real-time updates with onSnapshot
    const unsubscribe = onSnapshot(
      codesQuery,
      (snapshot) => {
        const userCodes: CodeDetails[] = [];
        snapshot.forEach((doc) => {
          const documentData = doc.data();
          userCodes.push({
            code: doc.id,
            createdAt: documentData?.created_at
              ? moment(
                  firebaseTimestampToDate(documentData?.created_at)
                ).toDate()
              : null,
            isRedeemed: documentData.is_redeemed,
            isRefunded: documentData.is_refaunded,
            redeemedAt: documentData.redeemed_at
              ? moment(
                  firebaseTimestampToDate(documentData.redeemed_at)
                ).toDate()
              : null,
            refundedAt: documentData.refaunded_at
              ? moment(
                  firebaseTimestampToDate(documentData.refaunded_at)
                ).toDate()
              : null,
            userId: documentData.user_id,
          } as CodeDetails);
        });
        setCodes(userCodes); // Update state with the fetched codes
      },
      (error) => {
        console.error("Error fetching user codes:", error);
        setCodes([]); // Handle potential errors by setting codes to null
      }
    );

    //cleanup the listener when the component unmounts or userId changes
    return () => unsubscribe();
  }, [userId]); // Re-run the effect if userId changes

  return codes;
};

export const updateCampaign = async (
  campaignId: string,
  updateData: Partial<Campaign>
): Promise<void> => {
  const campaignRef = doc(firestoreDb, DB_PATH_CAMPAIGNS, campaignId);
  await updateDoc(campaignRef, {
    ...updateData,
    updated_at: serverTimestamp(),
  });
};

export const deleteCampaign = async (campaignId: string): Promise<void> => {
  const campaignRef = doc(firestoreDb, DB_PATH_CAMPAIGNS, campaignId);

  // Delete all nested collections (e.g., items)
  const itemsCollectionRef = collection(campaignRef, DB_PATH_ITEMS);
  const itemsSnapshot = await getDocs(itemsCollectionRef);
  for (const itemDoc of itemsSnapshot.docs) {
    await deleteDoc(itemDoc.ref);
  }

  // Finally, delete the campaign document itself
  await deleteDoc(campaignRef);
};

export const getProduct = async (
  productId: string
): Promise<Product | null> => {
  const productRef = doc(firestoreDb, DB_PATH_PRODUCTS, productId);
  const productSnap = await getDoc(productRef);
  if (!productSnap.exists()) return null;
  return { id: productSnap.id, ...productSnap.data() } as Product;
};

export const useListenProductChanges = (productId?: string) => {
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!productId) {
      setLoading(false);
      setProduct(null);
      return;
    }

    const productRef = doc(firestoreDb, "products", productId);

    const unsubscribe = onSnapshot(
      productRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          const productData = {
            id: snapshot.id,
            created_at: firebaseTimestampToDate(data.created_at),
            updated_at: firebaseTimestampToDate(data.updated_at),
            ...data,
          } as Product;
          setProduct(productData);
        } else {
          setProduct(null);
        }
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [productId]);

  return { product, loading, error };
};

export const useListenCampaignChanges = (campaignId?: string) => {
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [isUnknownResourceError, setIsUnknownResourceError] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (!campaignId) {
      setCampaign(null);
      setIsUnknownResourceError(false);
      return;
    }

    const handleError = (err: Error) => {
      if (isMounted) {
        if (
          err.name === "FirebaseError" &&
          (err.message.includes("evaluation error") ||
            err.message.includes("permission-denied") ||
            err.message.includes("false for"))
        ) {
          setIsUnknownResourceError(true);
          setCampaign(null);
        }
      }
    };

    const campaignRef = doc(firestoreDb, "campaigns", campaignId);
    const itemsCollectionRef = collection(campaignRef, DB_PATH_ITEMS);

    const unsubscribeCampaign = onSnapshot(
      campaignRef,
      (snapshot) => {
        if (!isMounted) return;

        if (snapshot.exists()) {
          setIsUnknownResourceError(false);
          const data = snapshot.data();
          setCampaign((prevCampaign) => ({
            ...(data as Omit<
              Campaign,
              "id" | "created_at" | "updated_at" | "status" | "items"
            >),
            id: snapshot.id,
            created_at: firebaseTimestampToDate(data.created_at),
            updated_at: firebaseTimestampToDate(data.updated_at),
            status: data.status as LoadingStatus,
            items: prevCampaign?.items || [],
          }));
        } else {
          setCampaign(null);
          setIsUnknownResourceError(true);
        }
      },
      handleError
    );

    const unsubscribeItems = onSnapshot(
      itemsCollectionRef,
      (snapshot) => {
        if (!isMounted) return;

        setCampaign((prevCampaign) => {
          if (!prevCampaign) return null;

          const items: Item[] = snapshot.docs.map((doc) => {
            const itemData = doc.data();
            return {
              id: doc.id,
              ...itemData,
              created_at: firebaseTimestampToDate(itemData.created_at),
              updated_at: firebaseTimestampToDate(itemData.updated_at),
              versions:
                itemData.versions?.map((version: any) => ({
                  ...version,
                  created_at: firebaseTimestampToDate(version.created_at),
                  updated_at: firebaseTimestampToDate(version.updated_at),
                })) || [],
            } as Item;
          });

          return {
            ...prevCampaign,
            items,
          };
        });
      },
      handleError
    );

    return () => {
      isMounted = false;
      unsubscribeCampaign();
      unsubscribeItems();
    };
  }, [campaignId]);

  return { campaign, isUnknownResourceError };
};

export const useListenUserCampaigns = (userId?: string | null) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId?.length) {
      return;
    }

    const campaignsQuery = query(
      collection(firestoreDb, DB_PATH_CAMPAIGNS),
      where("user_id", "==", userId),
      orderBy("created_at", "desc")
    );

    const unsubscribe = onSnapshot(
      campaignsQuery,
      (snapshot) => {
        try {
          const userCampaigns: Campaign[] = snapshot.docs.map((doc) => {
            const campaignData = doc.data();
            return {
              ...campaignData,
              id: doc.id,
              created_at: firebaseTimestampToDate(campaignData.created_at),
              updated_at: firebaseTimestampToDate(campaignData.updated_at),
              status: campaignData.status as LoadingStatus,
            } as Campaign;
          });

          setCampaigns(userCampaigns);
          setLoading(false);
          setError(null);
        } catch (err) {
          console.error("Error fetching campaigns:", err);
          setError(
            err instanceof Error ? err : new Error("Unknown error occurred")
          );
          setLoading(false);
        }
      },
      (err) => {
        console.error("Error in campaigns snapshot:", err);
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  return { campaigns, loading, error };
};

export const useUserPlan = (planCode?: string) => {
  const [plan, setPlan] = useState<Plan | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!planCode) {
      setPlan(null);
      setLoading(false);
      return;
    }

    const plansRef = collection(firestoreDb, DB_PATH_PLANS);
    const planQuery = query(plansRef, where("code", "==", planCode));

    const unsubscribe = onSnapshot(
      planQuery,
      (snapshot) => {
        if (!snapshot.empty) {
          const planData = snapshot.docs[0].data();
          setPlan({
            id: snapshot.docs[0].id,
            name: planData.name,
            code: planData.code,
            interval: planData.interval,
            credits: planData.credits,
            isRenewableCredits: planData.isRenewableCredits,
            price: planData.price,
            created_at: firebaseTimestampToDate(planData.created_at),
            updated_at: firebaseTimestampToDate(planData.updated_at),
          });
        } else {
          setPlan(null);
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching plan:", error);
        setPlan(null);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [planCode]);

  return { plan, loading };
};

export const setUpdatesBannerClosed = async (
  userId?: string,
  bannerName?: string
) => {
  if (userId) {
    try {
      const userDocRef = doc(firestoreDb, DB_PATH_USERS, userId);
      await updateDoc(userDocRef, {
        [`flags.banners.${bannerName}_closed`]: true,
      });
    } catch (error) {
      console.error(
        "setUpdatesBannerClosed :: Error updating user flags:",
        error
      );
    }
  } else {
    console.error("setUpdatesBannerClosed :: No user ID provided");
  }
};

export const setUpdatesBannerClicked = async (
  userId?: string,
  bannerName?: string
) => {
  if (userId) {
    try {
      const userDocRef = doc(firestoreDb, DB_PATH_USERS, userId);
      await updateDoc(userDocRef, {
        [`flags.banners.${bannerName}_clicked`]: true,
      });
    } catch (error) {
      console.error(
        "setUpdatesBannerClicked :: Error updating user flags:",
        error
      );
    }
  } else {
    console.error("setUpdatesBannerClicked :: No user ID provided");
  }
};
