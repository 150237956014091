import { Grid, Typography } from '@mui/material';
import { filter, get, sortBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { generateTab, handleApiError as handleApiErrorUtil } from '../utils/api';
import { SECTIONS, SECTIONS_NAME_DESCRIPTION } from '../utils/const';
import { useFirebaseLoginState } from '../utils/hooks';
import { RootState } from '../utils/store';
import { getTranslationKey } from '../utils/translationUtils';
import { CampaignSection as CampaignSectionType, Item, LoadingStatus, RequestType, SectionName, TabName } from '../utils/types';
import { CampaignSectionItem } from './CampaignSectionItem';
import { CampaignSectionTabs } from './CampaignSectionTabs';
import { CampaignMetadataSectionComponent } from './minicomponents/minicomponents';
import AlertPopup from './popups/AlertPopup';
import LoadingPopup from './popups/LoadingPopup';
import RegeneratePopup from './popups/RegeneratePopup';
import UpgradePopup from './popups/UpgradePopup';

interface CampaignSectionProps {
  sectionName: SectionName;
}

const CampaignSection = ({ sectionName }: CampaignSectionProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabName | null>(null);
  const [generatePopupOpen, setGeneratePopupOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [tabToGenerate, setTabToGenerate] = useState<TabName | null>(null);
  const { userId } = useFirebaseLoginState();
  const [isUpgradePopupOpen, setUpgradePopupOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const navigate = useNavigate();

  const state = useSelector((state: RootState) => state.appState);
  const selectedCampaign = useSelector((state: RootState) => state.appState.selectedCampaign);

  const currentSection: CampaignSectionType | undefined = SECTIONS.find(section => section.name === sectionName);
  const hasTabs = !!currentSection?.tabs && !!currentSection.tabs?.length;

  const isTabRequireGeneration = (tabItems: RequestType[]) => {
    const allFetchedItemsCodes = selectedCampaign?.items?.map(item => item.type) || [];
    return !tabItems.some(code => allFetchedItemsCodes.includes(code));
  };

  const renderSectionItems = (itemTypes: RequestType[]) => {
    const sectionItemsObjects =
      selectedCampaign?.items &&
      (sortBy(
        filter(selectedCampaign.items, item => itemTypes.includes(item.type)),
        item => itemTypes.indexOf(item.type)
      ) as Item[]);
    return sectionItemsObjects?.map(item => {
      const itemInfo = get(SECTIONS_NAME_DESCRIPTION, item.type);
      return (
        <Grid size={12} container gap={1} key={item.id}>
          <Grid size={12}>
            <Typography variant="h2">{t(itemInfo?.name || item.type)}</Typography>
            <Typography variant="body1">{t(itemInfo?.description || item.type)}</Typography>
          </Grid>
          <CampaignSectionItem item={item} title={t(itemInfo?.name || item.type)} />
        </Grid>
      );
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabName) => {
    const tab = currentSection?.tabs?.find(tab => tab.name === newValue);
    if (tab && isTabRequireGeneration(tab.items)) {
      setTabToGenerate(newValue);
      setGeneratePopupOpen(true);
    } else {
      setActiveTab(newValue);
    }
  };

  const handleGenerateClose = () => {
    setGeneratePopupOpen(false);
  };

  const handleError = useCallback((error: unknown) => {
    handleApiErrorUtil({
      error,
      onSetUpgradePopupOpen: setUpgradePopupOpen,
      onSetErrorMessage: setError,
      onSetErrorAlertOpen: isOpen => {
        setIsAlertOpen(isOpen);
        if (!isOpen) setError(null);
      },
    });
  }, []);

  const handleGenerateSubmit = async (remarks: string) => {
    if (!userId || !selectedCampaign || !tabToGenerate) return;

    setIsGenerating(true);
    setGeneratePopupOpen(false);

    const tabItems = currentSection?.tabs?.find(tab => tab.name === tabToGenerate)?.items || [];

    try {
      await generateTab(userId, selectedCampaign.id!, state.session, tabItems, remarks);
      setActiveTab(tabToGenerate);
    } catch (error) {
      handleError(error);
    } finally {
      setIsGenerating(false);
      setTabToGenerate(null);
    }
  };

  const popups = (
    <>
      <RegeneratePopup
        open={generatePopupOpen}
        onClose={handleGenerateClose}
        onSubmit={handleGenerateSubmit}
        title={t('app.popups.generate.titleTab', {
          title: t(`app.sectionsNames.${getTranslationKey(sectionName)}`),
          tab: tabToGenerate ? t(`app.tabsNames.${getTranslationKey(tabToGenerate)}`) : '',
        })}
        buttonText={t('app.popups.generate.buttonText')}
        isRemarksMandatory={false}
      />
      <LoadingPopup
        open={isGenerating}
        title={t('app.popups.loading.generatingTitle', {
          section: t(`app.sectionsNames.${getTranslationKey(sectionName)}`),
          tab: tabToGenerate ? t(`app.tabsNames.${getTranslationKey(tabToGenerate)}`) : '',
        })}
        initialText={t('app.popups.loading.initialText')}
      />
      <UpgradePopup open={isUpgradePopupOpen} onClose={() => setUpgradePopupOpen(false)} onError={handleError} />
      <AlertPopup
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onSubmit={() => setIsAlertOpen(false)}
        alertText={error || ''}
        title={t('app.popups.error.title')}
        buttonText={t('app.buttons.ok')}
      />
    </>
  );

  // First check if it's Summary section
  if (sectionName === SectionName.Summary) {
    const summarySectionCode = SECTIONS.find(section => section.name === SectionName.Summary)?.tabs?.[0]?.items?.[0];
    const summary = selectedCampaign?.items?.find(item => item.type === summarySectionCode);

    return (
      <Grid size={12} container gap={2} alignContent="flex-start">
        <Grid size={12}>
          <CampaignMetadataSectionComponent createdAt={selectedCampaign?.created_at} language={selectedCampaign?.language} creativity={selectedCampaign?.creativity} isEvenSpacing={false} />
        </Grid>
        <CampaignSectionItem item={summary} title={t('app.campaign.marketingBrief')} isShowTitle={true} />
        {popups}
      </Grid>
    );
  }

  // Then, check if it has tabs
  if (hasTabs && currentSection) {
    const tabs = currentSection.tabs!;
    const activeTabItems = tabs.find(tab => tab.name === activeTab)?.items || tabs[0].items;

    const isTabItemsLoading = (tabItems: RequestType[]) => {
      return tabItems.some(code => selectedCampaign?.items?.some(item => item.type === code && item.status === LoadingStatus.LOADING));
    };

    const isTabItemsError = (tabItems: RequestType[]) => {
      return tabItems.some(code => selectedCampaign?.items?.some(item => item.type === code && item.status === LoadingStatus.FAILED));
    };

    return (
      <Grid size={12} container gap={3} alignContent="flex-start">
        <Grid size={12}>
          <Typography variant="h5">{t(`app.sectionsNames.${getTranslationKey(sectionName)}`)}</Typography>
          <CampaignSectionTabs
            tabs={tabs.map(tab => ({
              name: tab.name,
              isRequireGeneration: isTabRequireGeneration(tab.items),
              isLoading: isTabItemsLoading(tab.items),
              isError: isTabItemsError(tab.items),
            }))}
            activeTab={activeTab || tabs[0].name}
            onTabChange={handleTabChange}
          />
        </Grid>
        {renderSectionItems(activeTabItems)}
        {popups}
      </Grid>
    );
  }

  // Finally, if it's only one section, render the section
  return (
    <Grid size={12} container gap={3} alignContent="flex-start">
      {currentSection && renderSectionItems(currentSection.tabs?.flatMap(tab => tab.items) || [])}
      {popups}
    </Grid>
  );
};

export default React.memo(CampaignSection);
