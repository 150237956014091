import { Box, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import animationData from '../icons/lottie-loading-registered-users.json';
import AnimatedText from './minicomponents/AnimatedText';

// Styled components
const LoadingGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const TextContainer = styled(Box)({
  textAlign: 'center',
  marginBottom: '16px',
});

const SCENE_DURATION_SEC = 10;

export default function LoadingResultsAnimation() {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const { t } = useTranslation();

  // Constants for the progress bar and text animation
  const loadingTexts = [t('app.labels.openingWebsite'), t('app.labels.scanningInformation'), t('app.labels.generatingResults')];

  // Lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // Handle scene changes
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex(prevIndex => {
        if (prevIndex === loadingTexts.length - 1) {
          clearInterval(interval);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, SCENE_DURATION_SEC * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <LoadingGrid container>
        <Grid size={12}>{<Lottie options={defaultOptions} height={200} width={200} />}</Grid>
        <Grid size={12}>
          <TextContainer>
            <AnimatedText text={loadingTexts[currentTextIndex]} key={currentTextIndex} />
          </TextContainer>
        </Grid>
      </LoadingGrid>
    </Box>
  );
}
