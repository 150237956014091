import { Box, CircularProgress, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { sortBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import { handleApiError as handleApiErrorUtil, regenerateItem } from '../utils/api';
import { colors } from '../utils/colors';
import { ANIMATION_DURATION_MS } from '../utils/const';
import { formatCustomDistanceToNow } from '../utils/dateUtils';
import { useFirebaseLoginState, useIsPaidUserStatus } from '../utils/hooks';
import { RootState } from '../utils/store';
import { postProcessSummary as postProcessMarkdown } from '../utils/textUtils';
import { Item, LoadingStatus } from '../utils/types';
import { IconError, IconKeyboardArrowDown, IconNotes, IconReload } from './minicomponents/icons';
import { InfoTooltipView } from './minicomponents/InfoTooltipView';
import { ButtonWithIcon, CopyButton, ReactMarkdownStyled } from './minicomponents/minicomponents';
import AlertPopup from './popups/AlertPopup';
import LoadingPopup from './popups/LoadingPopup';
import RegeneratePopup from './popups/RegeneratePopup';
import UpgradePopup from './popups/UpgradePopup';

interface CampaignItemSectionComponentProps {
  item?: Item | null;
  title?: string;
  isShowTitle?: boolean;
  isAnimated?: boolean;
}

export const CampaignSectionItem = React.memo(
  ({ item, title, isShowTitle = false, isAnimated = false }: CampaignItemSectionComponentProps) => {
    const { userId } = useFirebaseLoginState();

    const state = useSelector((state: RootState) => state.appState);
    const selectedCampaignId = useSelector((state: RootState) => state.appState.selectedCampaign)?.id;

    const isStatusLoading = item?.status === LoadingStatus.LOADING;
    const isStatusCompleted = item?.status === LoadingStatus.COMPLETED;
    const isStatusFailed = item?.status === LoadingStatus.FAILED;

    const versionsObject = item?.versions || [];
    const sortedVersions = useMemo(() => sortBy(versionsObject, v => v.created_at).reverse(), [versionsObject]);

    const [activeVersionIndex, setActiveVersionIndex] = useState(0);
    const activeVersion = sortedVersions[activeVersionIndex];

    const markdownClean = postProcessMarkdown(activeVersion?.text || '');

    const totalVersions = sortedVersions.length;
    const hasMultipleVersions = totalVersions > 1;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const [regeneratePopupOpen, setRegeneratePopupOpen] = useState(false);
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [regenerateError, setRegenerateError] = useState<string | null>(null);

    const [upgradePopupOpen, setUpgradePopupOpen] = useState(false);

    const isPaidUser = useIsPaidUserStatus(state?.subcription?.status);

    const handlePreviousVersion = () => {
      if (activeVersionIndex > 0) {
        setActiveVersionIndex(activeVersionIndex - 1);
      }
    };

    const handleNextVersion = () => {
      if (activeVersionIndex < totalVersions - 1) {
        setActiveVersionIndex(activeVersionIndex + 1);
      }
    };

    const handleVersionMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleVersionMenuClose = () => {
      setAnchorEl(null);
    };

    const handleVersionSelect = (index: number) => {
      setActiveVersionIndex(index);
      handleVersionMenuClose();
    };

    // Step 1: Replace handleNotes to use InfoTooltipView
    const handleNotes = () => {
      // This function is no longer needed as InfoTooltipView handles tooltip
    };

    const handleRegenerateButtonClick = async () => {
      if (isStatusFailed && userId && selectedCampaignId) {
        await handleRegenerateSubmit(activeVersion?.prompt || '');
      } else if (!isPaidUser) {
        setUpgradePopupOpen(true);
        return;
      } else {
        setRegeneratePopupOpen(true);
      }
    };

    const handleError = useCallback((error: unknown) => {
      handleApiErrorUtil({
        error,
        onSetUpgradePopupOpen: setUpgradePopupOpen,
        onSetErrorMessage: setRegenerateError,
        onSetErrorAlertOpen: isOpen => {
          if (!isOpen) {
            setRegenerateError(null);
          }
        },
        onSetErrorButtonText: () => {},
        onSetErrorAction: () => {},
      });
    }, []);

    const handleRegenerateSubmit = async (prompt: string) => {
      if (!userId || !selectedCampaignId || !item) return;

      setIsRegenerating(true);
      setRegenerateError(null);

      try {
        const result = await regenerateItem(userId, selectedCampaignId, item.id!, activeVersion?.version || 0, state.creativity, state.session, prompt);

        // Update the local state with the new version
        const updatedVersions = [...sortedVersions];
        updatedVersions.unshift(result.new_version);
        setActiveVersionIndex(0);
        // You might need to update your global state here as well, depending on your state management approach
      } catch (error) {
        handleError(error);
      } finally {
        setIsRegenerating(false);
        setRegeneratePopupOpen(false);
      }
    };

    const { t } = useTranslation();

    return (
      <Grid container size={12} sx={{ borderRadius: '6px', bgcolor: colors.custom.bgGrey }}>
        {(isStatusCompleted || isStatusFailed || isShowTitle) && (
          <Grid
            container
            size={12}
            sx={{
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
              bgcolor: colors.primary.light,
              alignItems: 'center',
              px: 2,
              height: 32,
            }}>
            {isShowTitle && <Grid size={6}>{title && <Typography variant="h6">{title}</Typography>}</Grid>}
            {(isStatusCompleted || isStatusFailed) && (
              <Grid size={'grow'} container justifyContent="flex-end" alignItems="center">
                {hasMultipleVersions && (
                  <>
                    <ButtonWithIcon
                      icon={<IconKeyboardArrowDown sx={{ rotate: '90deg' }} />}
                      isAccentOnHover={false}
                      onClick={handleNextVersion}
                      isDisabled={activeVersionIndex === totalVersions - 1}
                    />
                    <ButtonWithIcon
                      onClick={handleVersionMenuOpen}
                      icon={
                        <Typography
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { color: 'primary.main' },
                          }}>
                          {t('app.campaign.version', { current: totalVersions - activeVersionIndex, total: totalVersions })}
                        </Typography>
                      }
                      sx={{
                        minWidth: 90,
                      }}
                      isAccentOnHover={true}
                    />
                    <Menu id="version-menu" anchorEl={anchorEl} open={menuOpen} onClose={handleVersionMenuClose}>
                      {sortedVersions.map((version, index) => (
                        <MenuItem key={version.version} onClick={() => handleVersionSelect(index)}>
                          <Typography fontWeight={index === activeVersionIndex ? 'bold' : 'normal'}>
                            {totalVersions - index}) {formatCustomDistanceToNow(version.created_at!)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                    <InfoTooltipView
                      text={activeVersion?.prompt || ''}
                      icon={<ButtonWithIcon icon={<IconNotes />} onClick={handleNotes} isAccentOnHover={false} isDisabled={!activeVersion?.prompt} />}
                      isDisabled={!activeVersion?.prompt}
                    />
                    <ButtonWithIcon icon={<IconKeyboardArrowDown sx={{ rotate: '270deg' }} />} isAccentOnHover={false} onClick={handlePreviousVersion} isDisabled={activeVersionIndex === 0} />
                  </>
                )}
                {isStatusCompleted && <InfoTooltipView icon={<CopyButton textToCopy={markdownClean} />} text={t('app.popups.copy.tooltip', { title })} />}
                <InfoTooltipView
                  icon={
                    <ButtonWithIcon
                      icon={<IconReload />}
                      onClick={handleRegenerateButtonClick}
                      isAccentOnHover={false}
                      sx={{
                        color: isPaidUser || isStatusFailed ? 'inherit' : grey[500],
                        '&:hover': {
                          backgroundColor: isPaidUser || isStatusFailed ? 'primary.light' : 'transparent',
                        },
                      }}
                    />
                  }
                  text={isStatusFailed ? t('app.popups.regenerate.recoverText') : t('app.popups.regenerate.customizeText', { title })}
                />
                <RegeneratePopup
                  open={regeneratePopupOpen}
                  onClose={() => setRegeneratePopupOpen(false)}
                  onSubmit={handleRegenerateSubmit}
                  title={t('app.popups.regenerate.title', { title })}
                  buttonText={t('app.popups.regenerate.buttonText')}
                  isRemarksMandatory={false}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid size={12} sx={{ px: 3, pb: 2, color: colors.primary.dark, textAlign: !isStatusCompleted ? 'center' : 'left', mt: isStatusLoading ? 2 : 0 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: isAnimated ? ANIMATION_DURATION_MS / 1000 : 0,
              ease: 'easeOut',
            }}>
            {isStatusLoading && <CircularProgress size={24} sx={{ color: 'text.secondary' }} />}
            {isStatusFailed && (
              <Box pt={2}>
                <IconError sx={{ color: 'error.main', width: 24, height: 24 }} />
                <Typography>{t('app.errors.failedToGenerate')}</Typography>
                <Typography color="text.secondary">{t('app.errors.noChargeForFailedGeneration')}</Typography>
              </Box>
            )}
            {isStatusCompleted && (
              <>
                <ReactMarkdownStyled remarkPlugins={[remarkGfm, remarkBreaks]} rehypePlugins={[rehypeRaw, rehypeHighlight]}>
                  {markdownClean}
                </ReactMarkdownStyled>
              </>
            )}
          </motion.div>
        </Grid>
        <LoadingPopup open={isRegenerating} title={t('app.popups.loading.regeneratingTitle', { title })} initialText={t('app.popups.loading.craftingNewVersion', { title })} />
        <AlertPopup
          open={!!regenerateError}
          onClose={() => setRegenerateError(null)}
          onSubmit={() => setRegenerateError(null)}
          buttonText={t('app.buttons.ok')}
          alertText={regenerateError || ''}
          title={t('app.popups.error.title')}
        />
        <UpgradePopup open={upgradePopupOpen} onClose={() => setUpgradePopupOpen(false)} onError={handleError} />
      </Grid>
    );
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
    prevProps.title === nextProps.title &&
    prevProps.isShowTitle === nextProps.isShowTitle &&
    prevProps.isAnimated === nextProps.isAnimated
);
