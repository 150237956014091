import { Card, CardContent, CircularProgress, Fade, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ANIMATION_DURATION_MS } from '../utils/const';
import { deleteCampaign, getProduct, updateCampaign } from '../utils/db';
import { parseDomainFromUrl } from '../utils/textUtils';
import { Campaign, LoadingStatus, Product } from '../utils/types';
import { IconEditNote, IconError, IconMoreHorizontal } from './minicomponents/icons';
import { CampaignMetadataSectionComponent } from './minicomponents/minicomponents';
import AlertPopup from './popups/AlertPopup';
import InputPopup from './popups/InputPopup';

const CampaignListItem = ({ campaign }: { campaign: Campaign }) => {
  const [product, setProduct] = useState<Product | null>();
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const [isIconHovered, setIsIconHovered] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog visibility
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false); // State for delete confirmation
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProduct = async () => {
      const fetchedProduct = await getProduct(campaign?.product_id!);
      setProduct(fetchedProduct);
      setLoading(false);
    };
    if (campaign?.product_id) {
      fetchProduct();
    }
  }, [campaign?.product_id]);

  if (loading) return <></>;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  // const handleDownload = async (id?: string) => {
  //   if (id) {
  //     console.log('Download campaign with id:', id);
  //     handleClose();
  //   }
  // };

  const handleRename = async (id?: string) => {
    if (!id) return;
    setDialogOpen(true); // Open the InputPopup
    handleClose();
  };

  const handleDialogSubmit = async (newName: string) => {
    if (newName) {
      await updateCampaign(campaign.id!, { name: newName }); // Call updateCampaign
    }
    setDialogOpen(false); // Close the dialog
  };

  const handleDelete = async (id?: string) => {
    if (id) {
      handleClose();
      setDeleteConfirmationOpen(true); // Open the AlertPopup
    }
  };

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleClick(event);
  };

  const handleIconMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsIconHovered(true);
  };

  const handleIconMouseLeave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsIconHovered(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false); // Close the dialog
  };

  const renderMenuIcon = () => {
    if (campaign.status === LoadingStatus.FAILED) {
      return <IconError sx={{ color: 'error.main', width: 24, height: 24 }} />;
    } else if (campaign.status === LoadingStatus.LOADING) {
      return <CircularProgress size={24} sx={{ color: 'text.secondary' }} />;
    } else {
      return <IconMoreHorizontal />;
    }
  };

  return (
    <>
      <Grid size={12} sx={{ width: '100%' }}>
        <Fade in={true} timeout={ANIMATION_DURATION_MS}>
          <Card
            sx={{
              maxHeight: 76,
              boxShadow: 0,
              borderRadius: '6px', // Set border radius to 6px
              '& .MuiCardContent-root:last-child': {
                pb: 2,
              },
              '&:hover': {
                backgroundColor: isIconHovered ? 'default' : 'primary.light',
                cursor: isIconHovered ? 'default' : 'pointer',
                '& #campaignText': {
                  color: isIconHovered ? 'inherit' : 'primary.main',
                },
              },
            }}
            onClick={() => navigate(`/app/campaigns/${campaign.id}`)} // Use navigate for routing
          >
            <CardContent>
              <Grid container alignItems={'center'} gap={2} sx={{ p: 0 }}>
                {/* Left side: Campaign logo and name */}
                <Grid size={5}>
                  <Grid container size={12} alignItems="center">
                    <Grid sx={{ maxWidth: 32, alignContent: 'center' }}>
                      {product?.favicon && <img src={product?.favicon} alt={campaign.name} style={{ width: 24, height: 24, marginRight: 8 }} />}
                      {!product?.favicon && <IconEditNote sx={{ width: 24, height: 24, marginRight: 8, mt: 0.5 }} />}
                    </Grid>
                    <Grid size={'grow'}>
                      <Typography
                        id="campaignText" // Added id for targeting
                        variant="h6"
                        sx={{
                          textDecoration: 'none',
                          color: 'inherit',
                          whiteSpace: 'nowrap', // Prevent line breaks
                          overflow: 'hidden', // Hide overflow
                          textOverflow: 'ellipsis', // Add ellipsis for overflow
                        }}>
                        {parseDomainFromUrl(product?.url) ? (
                          <>
                            {parseDomainFromUrl(product?.url)}&nbsp;&nbsp;|&nbsp;&nbsp;{campaign.name}
                          </>
                        ) : (
                          campaign.name
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {product?.description && (
                    <Grid size={12}>
                      <Typography variant="body1" color={'text.secondary'} noWrap>
                        {product?.description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                {/* Right side: Campaign details */}
                <CampaignMetadataSectionComponent
                  createdAt={campaign.created_at}
                  language={campaign.language}
                  creativity={campaign.creativity}
                  isEvenSpacing={true}
                  sx={{ justifyContent: 'center', pl: 4 }}
                />

                {/* Menu icon */}
                <Grid>
                  <IconButton
                    aria-label="more"
                    onClick={handleIconClick}
                    onMouseEnter={handleIconMouseEnter}
                    onMouseLeave={handleIconMouseLeave}
                    disabled={campaign.status === LoadingStatus.LOADING || campaign.status === LoadingStatus.FAILED}>
                    {renderMenuIcon()}
                  </IconButton>
                  <Menu id="simple-menu" anchorEl={anchorEl} open={open} onClose={handleClose} onClick={e => e.stopPropagation()}>
                    {/* <MenuItem
                      onClick={() => {
                        handleDownload(campaign.id);
                      }}>
                      Download
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => {
                        handleRename(campaign.id);
                      }}>
                      {t('app.buttons.rename')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleDelete(campaign.id);
                      }}>
                      {t('app.buttons.delete')}
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Fade>
      </Grid>

      {/* InputPopup for renaming campaign */}
      <InputPopup
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        buttonText={t('app.buttons.rename')}
        dialogTitle={t('app.popups.rename.title')}
        dialogText={t('app.popups.rename.text')}
        inputValue={campaign.name || t('app.popups.rename.defaultName')}
      />

      {/* AlertPopup for delete confirmation */}
      <AlertPopup
        open={isDeleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onSubmit={async () => {
          await deleteCampaign(campaign.id!);
          setDeleteConfirmationOpen(false);
        }}
        buttonText={t('app.buttons.delete')}
        alertText={t('app.popups.delete.text')}
        title={t('app.popups.delete.title')}
        buttonColor="error.main"
      />
    </>
  );
};

export default CampaignListItem;
