import { WEBSITE_LANGUAGES } from "./const";
import { breakpoints } from "./theme";

export enum RequestType {
  FACEBOOK_AD_TEXT_IMG_IDEAS = "FACEBOOK_AD_TEXT_IMG_IDEAS",
  FACEBOOK_BARRIERS = "FACEBOOK_BARRIERS",
  FACEBOOK_POSTS = "FACEBOOK_POSTS",
  FACEBOOK_POST_IDEAS = "FACEBOOK_POST_IDEAS",
  FACEBOOK_NEED_SPECIFIC_ADS = "FACEBOOK_NEED_SPECIFIC_ADS",
  FACEBOOK_EDUCATIONAL_POST = "FACEBOOK_EDUCATIONAL_POST",
  GOOGLE_ADS_TEXT = "GOOGLE_ADS_TEXT",
  GOOGLE_NEED_SPECIFIC_ADS = "GOOGLE_NEED_SPECIFIC_ADS",
  GOOGLE_ADS_BARRIERS = "GOOGLE_ADS_BARRIERS",
  GOOGLE_ADS_DISPLAY = "GOOGLE_ADS_DISPLAY",
  GOOGLE_ADS_VIDEO = "GOOGLE_ADS_VIDEO",
  STRATEGY_BARRIERS = "STRATEGY_BARRIERS",
  STRATEGY_JOBS_TO_BE_DONE = "STRATEGY_JOBS_TO_BE_DONE",
  STRATEGY_CUSTOMER_PERSONA = "STRATEGY_CUSTOMER_PERSONA",
  STRATEGY_MARKETING_IDEAS = "STRATEGY_MARKETING_IDEAS",
  STRATEGY_VALUE_PROPOSITION = "STRATEGY_VALUE_PROPOSITION",
  STRATEGY_POSITIONING = "STRATEGY_POSITIONING",
  STRATEGY_CONSUMER_DECISION_JOURNEY = "STRATEGY_CONSUMER_DECISION_JOURNEY",
  STRATEGY_BUSINESS_MODEL_CANVAS = "STRATEGY_BUSINESS_MODEL_CANVAS",
  STRATEGY_VALUE_PROPOSITION_CANVAS = "STRATEGY_VALUE_PROPOSITION_CANVAS",
  FRAMEWORKS_STEPPS = "FRAMEWORKS_STEPPS",
  FRAMEWORKS_7P_OF_MARKETING = "FRAMEWORKS_7P_OF_MARKETING",
  FRAMEWORKS_SWOT = "FRAMEWORKS_SWOT",
  FRAMEWORKS_PORTERS_FIVE_FORCES = "FRAMEWORKS_PORTERS_FIVE_FORCES",
  FRAMEWORKS_AIDA = "FRAMEWORKS_AIDA",
  FRAMEWORKS_AARRR = "FRAMEWORKS_AARRR",
  FRAMEWORKS_LEAN_ANALYTICS_STAGES = "FRAMEWORKS_LEAN_ANALYTICS_STAGES",
  FRAMEWORKS_HOOK_FRAMEWORK = "FRAMEWORKS_HOOK_FRAMEWORK",
  FRAMEWORKS_ICE_SCORE = "FRAMEWORKS_ICE_SCORE",
  FRAMEWORKS_ANSOFF_MATRIX = "FRAMEWORKS_ANSOFF_MATRIX",
  EMAIL_SUBJECT = "EMAIL_SUBJECT",
  EMAIL_PROMOTIONAL = "EMAIL_PROMOTIONAL",
  EMAIL_MARKETING_TOPICS = "EMAIL_MARKETING_TOPICS",
  LINKEDIN_AD_TEXT_IMG_IDEAS = "LINKEDIN_AD_TEXT_IMG_IDEAS",
  LINKEDIN_NEED_SPECIFIC_ADS = "LINKEDIN_NEED_SPECIFIC_ADS",
  LINKEDIN_BARRIERS = "LINKEDIN_BARRIERS",
  LINKEDIN_POSTS = "LINKEDIN_POSTS",
  LINKEDIN_POST_IDEAS = "LINKEDIN_POST_IDEAS",
  LINKEDIN_EDUCATIONAL_POST = "LINKEDIN_EDUCATIONAL_POST",
  INSTAGRAM_AD_TEXT_IMG_IDEAS = "INSTAGRAM_AD_TEXT_IMG_IDEAS",
  INSTAGRAM_CAPTIONS = "INSTAGRAM_CAPTIONS",
  INSTAGRAM_POST_IDEAS = "INSTAGRAM_POST_IDEAS",
  INSTAGRAM_BARRIERS = "INSTAGRAM_BARRIERS",
  INSTAGRAM_NEED_SPECIFIC_ADS = "INSTAGRAM_NEED_SPECIFIC_ADS",
  INSTAGRAM_JOKES_AND_STORIES = "INSTAGRAM_JOKES_AND_STORIES",
  INSTAGRAM_HASHTAG = "INSTAGRAM_HASHTAG",
  INSTAGRAM_ADS_VIDEO = "INSTAGRAM_ADS_VIDEO",
  INSTAGRAM_EDUCATIONAL_POST = "INSTAGRAM_EDUCATIONAL_POST",
  TIKTOK_VIDEO = "TIKTOK_VIDEO",
  TIKTOK_CONTENT_STRATEGY = "TIKTOK_CONTENT_STRATEGY",
  TIKTOK_HASHTAGS = "TIKTOK_HASHTAGS",
  TIKTOK_BIO = "TIKTOK_BIO",
  PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO = "PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO",
  PR_CAMPAIGN_IDEAS = "PR_CAMPAIGN_IDEAS",
  PR_ARTICLE_IDEAS = "PR_ARTICLE_IDEAS",
  PR_GUEST_ARTICLE = "PR_GUEST_ARTICLE",
  YOUTUBE_TITLES_PROMO_VIDEO = "YOUTUBE_TITLES_PROMO_VIDEO",
  YOUTUBE_VIDEO_IDEAS = "YOUTUBE_VIDEO_IDEAS",
  YOUTUBE_KEYWORDS = "YOUTUBE_KEYWORDS",
  YOUTUBE_ADS_VIDEO = "YOUTUBE_ADS_VIDEO",
  BLOG_IDEAS = "BLOG_IDEAS",
  BLOG_PROMO_ARTICLE = "BLOG_PROMO_ARTICLE",
  BLOG_KEYWORDS = "BLOG_KEYWORDS",
  BLOG_FAQ = "BLOG_FAQ",
  BLOG_EDUCATIONAL_ARTICLE = "BLOG_EDUCATIONAL_ARTICLE",
  SEO_COMMON_KEYWORDS = "SEO_COMMON_KEYWORDS",
  SEO_LONG_TAIL_KEYWORDS = "SEO_LONG_TAIL_KEYWORDS",
  SEO_META_TITLE_AND_DESCRIPTION = "SEO_META_TITLE_AND_DESCRIPTION",
  BRAND_GUIDE = "BRAND_GUIDE",
  MARKETING_BRIEF = "MARKETING_BRIEF",
}

export enum SectionName {
  Summary = "Summary",
  Ads = "Ads",
  Strategy = "Strategy",
  SMM = "SMM",
  SEO = "SEO",
  Email = "Email",
  Articles = "Articles",
  Brand = "Brand",
  PR = "PR",
  FrameworksAndIdeas = "Frameworks & Ideas",
  Video = "Video",
}

export enum TabName {
  // Summary tab
  Summary = "Summary",

  // Ads and SMM related tabs
  Facebook = "Facebook",
  Google = "Google",
  Instagram = "Instagram",
  LinkedIn = "LinkedIn",
  TikTok = "TikTok",

  // Strategy related tabs
  Barriers = "Barriers",
  NeedsJTBD = "Needs/JTBD",
  Persona = "Customer Persona",
  ValuePropositionCanvas = "VP Canvas",
  ValueProposition = "Value Proposition",
  Positioning = "Positioning",
  BusinessModel = "Business Model",
  DecisionJourney = "Decision Journey",

  // SEO related tabs
  CommonKeywords = "Common Keywords",
  LongTailKeywords = "Long-tail Keywords",
  MetaTitleDescription = "Meta Data",

  // Email related tabs
  EmailSubject = "Email Subject",
  PromotionalEmail = "Promotional Email",
  EmailMarketingTopics = "Newsletter Topics",

  // Content related tabs
  ContentPlan = "Content Plan",
  PromoArticle = "Promo Article",
  FAQ = "FAQ",
  SemanticCore = "Semantic Core",
  EducationalArticle = "Educational Article",

  // Brand related tabs
  BrandGuide = "Brand Guide",

  // PR related tabs
  CampaignIdeas = "Campaign Ideas",
  GuestArticle = "Guest Article",
  PRArticleIdeas = "PR Article Ideas",
  ListOfMediaToUseForPromo = "List of Media to Use for Promo",

  // Frameworks and Ideas related tabs
  MarketingIdeas = "Marketing Ideas",
  STEPPS = "STEPPS",
  SevenP = "7P",
  SWOT = "SWOT",
  PortersFiveForces = "Porters Five Forces",
  AIDA = "AIDA",
  AARRR = "AARRR",
  LeanAnalyticsStages = "Lean Analytics Stages",
  HOOK = "HOOK",
  ICEScore = "ICEScore",
  AnsoffMatrix = "AnsoffMatrix",

  // Video related tabs
  YouTubeAdScript = "YouTube Ad Script",
  Keywords = "Keywords",
  TitlesDescriptions = "Titles Descriptions",
  InstagramReel = "Instagram Reel",
  GoogleAds = "Google Ads",
  TikTokAd = "TikTok Ad",
}

export interface CodeDetails {
  code: string;
  createdAt: Date | null;
  isRedeemed: boolean;
  isRefunded: boolean;
  redeemedAt: Date | null;
  refundedAt: Date | null;
  userId: string | null;
}

interface DBObject {
  id?: string;
  created_at?: Date | null;
  updated_at?: Date | null;
}

interface Tokens {
  input: number;
  output: number;
  total: number;
}

export enum LoadingStatus {
  LOADING = "loading",
  COMPLETED = "completed",
  FAILED = "failed",
}

export interface Version {
  version: number;
  creativity: string;
  prompt: string;
  text: string;
  tokens: Tokens;
  created_at: Date | null;
  updated_at: Date | null;
}

export interface Item extends DBObject {
  campaign_id: string;
  type: RequestType;
  versions: Version[];
  status: LoadingStatus;
}

export interface Campaign extends DBObject {
  user_id: string;
  product_id?: string;
  name: string;
  description?: string;
  creativity?: string;
  language?: string;
  items?: Item[];
  status: LoadingStatus;
}

export interface Product extends DBObject {
  user_id: string;
  campaign_id: string;
  url?: string;
  prompt?: string;
  title?: string;
  description?: string;
  favicon?: string;
  is_cloudflare?: boolean;
}
export interface Plan extends DBObject {
  name: string;
  code: PlanCode;
  credits: number;
  interval: string;
  isRenewableCredits: boolean;
  price: number;
}

export interface Tab {
  name: TabName;
  items: RequestType[];
}

export interface AppsumoPlan {
  id: number;
  name: string; // shown in the UI
  minCodesToRedeem: number; // incluseive
  reqPerMonth: number; // optional
}

export interface OutputLanguageData {
  code: string;
  name: string;
}

export type ScreenWidth = keyof typeof breakpoints.values;
export type Creativity = "conservative" | "standard" | "spicy" | "crazy";

export type Page = {
  title: string;
  subtitle: string;
  subtitleDescription: string;
  content: string;
};

export type Mode = "url" | "manual";
export type OpenAiTokenConsumptionType = "input" | "output" | "total";

export type SubscriptionStatus =
  | "paid_active" // user is paying and subscription is not canceled
  | "paid_active_limited" // user is paying and subscription is not canceled but requests limit is reached
  | "canceled_active" // subscription was canceled but user still has access until the end of the period
  | "canceled_past_due" // subscription was canceled and user lost access
  | "freemium"; // user is not paying

export type PlanCode =
  | "not_registered"
  | "free"
  | "begin_monthly"
  | "begin_annual"
  | "begin_lifetime"
  | "advance_monthly"
  | "advance_annual"
  | "advance_lifetime"
  | "conquer_monthly"
  | "conquer_annual"
  | "conquer_lifetime";

export interface StripeUser {
  amountTotal?: number;
  cancelAtPeriodEnd?: boolean;
  cancelAt?: number;
  currencyCode?: string;
  customerId?: string;
  planId?: string;
  planInterval?: "month" | "year";
  subscriptionName?: string;
  current_period_start: string;
  current_period_end: string;
}

export interface SubscriptionInfo {
  status: SubscriptionStatus;
  dateStart: Date;
  dateEnd: Date;
  dateCycleStart: Date;
  dateCycleEnd: Date;
  credits: number;
  planCode: PlanCode;
}

interface FirebaseTimestamp {
  __time__: string;
}

export interface State {
  url: string;
  prompt: string;
  creativity: Creativity;
  session: string;
  stripe?: StripeUser;
  subcription?: SubscriptionInfo;
  outputLanguage: OutputLanguageData;
  isOutputLanguageManuallySelected?: boolean;
  urlError?: string;
  mode: Mode;
  selectedCampaign: Campaign | null;
  selectedProduct: Product | null;
  flags: {
    isUserMigratedToCredits: boolean;
  };
  subscription: {
    cycle_start_date: FirebaseTimestamp;
    cycle_end_date: FirebaseTimestamp;
    start_date: FirebaseTimestamp;
    end_date: FirebaseTimestamp;
    credits: number;
    plan_code: string;
    status: string;
  };
}

export type languagesType = (typeof WEBSITE_LANGUAGES)[number];

export interface CampaignSection {
  name: SectionName;
  icon: React.ElementType;
  tabs: Tab[];
  description?: string;
  isDisabled?: boolean;
}
