import { CloseOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, Divider, IconButton, MenuItem, styled, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid'; // Updated import
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Facebook from '../icons/Facebook.webp';
import Linkedin from '../icons/LinkedIn.webp';
import Twitter from '../icons/Twitter.webp';
import {
  trackClickBenefitsHeader,
  trackClickFacebookHeader,
  trackClickFeaturesHeader,
  trackClickHowItWorksHeader,
  trackClickLinkedinHeader,
  trackClickTwitterHeader,
  trackOpenNavigationMenu,
} from '../utils/analytics';
import { createNewCampaign, handleApiError as handleApiErrorUtil } from '../utils/api';
import { registerAsync } from '../utils/auth';
import { useFirebaseLoginState } from '../utils/hooks';
import { resetInputDataSection } from '../utils/state';
import { RootState } from '../utils/store';
import { GoogleAuthProfilePicButton } from './minicomponents/GoogleAuthProfilePicButton';
import { GradientButton } from './minicomponents/GradientButton';
import { IconAddCircle } from './minicomponents/icons';
import LanguageSwitcher from './minicomponents/LanguageSwitcher';
import { RequestsCounter } from './minicomponents/RequestsCounter';
import AlertPopup from './popups/AlertPopup';
import LoadingPopup from './popups/LoadingPopup';
import NewCampaignPopup from './popups/NewCampaignPopup';
import { PricingPopupNonAuthUsers } from './popups/PricingPopupNonAuthUsers';
import UpgradePopup from './popups/UpgradePopup';

const HeaderButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));

function SocialIconButtonComponent({ icon, url, callback }: { icon: any; url: string; callback?: () => void }) {
  return (
    <IconButton
      sx={{
        width: 36,
        height: 36,
        '&:hover': {
          bgcolor: 'primary.light',
        },
      }}
      onClick={() => {
        window.open(url);
        if (callback) {
          callback();
        }
      }}>
      <img src={icon} alt="icon" style={{ width: 24, height: 24 }} />
    </IconButton>
  );
}

function BlogMenuItem() {
  const isTabletUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'));
  const { t } = useTranslation();

  function getNavigateAction() {
    window.location.href = '/blog';
  }

  return isTabletUp ? (
    <HeaderButton key={'Blog'} onClick={getNavigateAction}>
      {t('website.header.blog')}
    </HeaderButton>
  ) : (
    <MenuItem key={'Blog'} onClick={getNavigateAction}>
      <Typography textAlign="center">{t('website.header.blog')}</Typography>
    </MenuItem>
  );
}

export default function Header() {
  const theme = useTheme();

  const isTabletUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'));
  const isLaptopUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('laptop'));

  const { t } = useTranslation();
  const { isRegistered, userId } = useFirebaseLoginState();
  const state = useSelector((state: RootState) => state.appState);

  const [isUpgradePopupOpen, setUpgradePopupOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const isCampaignsScreen = location.pathname.includes('/app/campaigns') && location.pathname == '/app/campaigns';
  const isCampaignDetailsScreen = location.pathname.startsWith('/app/campaigns/') && location.pathname !== '/app/campaigns';
  const isProfileScreen = location.pathname.includes('/app/profile') && location.pathname === '/app/profile';

  const [isNewCampaignPopupOpen, setNewCampaignPopupOpen] = useState(false);
  const [isLoadingProgressOpen, setLoadingProgressOpen] = useState(false);
  const [isAlertPopupOpen, setAlertPopupOpen] = useState(false);
  const [isPricingPopupOpen, setPricingPopupOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

  const pages = [
    { title: t('website.header.howItWorks'), anchor: 'how-does-it-work' },
    { title: t('website.header.benefits'), anchor: 'benefits' },
    { title: t('website.header.features'), anchor: 'features' },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    trackOpenNavigationMenu();
    setIsMenuOpen(true);
  };

  const handleCloseNavMenu = () => {
    setIsMenuOpen(false);
  };

  function onMenuItemClick(page: { anchor: string; title: string }) {
    if (page?.title === 'How it works') {
      trackClickHowItWorksHeader();
    } else if (page?.title === 'Benefits') {
      trackClickBenefitsHeader();
    } else if (page?.title === 'Features') {
      trackClickFeaturesHeader();
    }

    handleCloseNavMenu();
  }

  const dispatch = useDispatch();

  const handleNewCampaignOpenPopup = () => {
    setNewCampaignPopupOpen(true);
  };

  const handleNewCampaignClosePopup = () => {
    setNewCampaignPopupOpen(false);
  };

  const handleAlertClosePopup = () => {
    setAlertPopupOpen(false);
  };

  const onCreateNewCampaignButtonHandler = () => {
    handleNewCampaignOpenPopup();
  };

  const handleError = useCallback((error: unknown) => {
    handleApiErrorUtil({
      error,
      onSetUpgradePopupOpen: setUpgradePopupOpen,
      onSetErrorMessage: (message: string) => {
        setAlertMessage(message);
        setAlertPopupOpen(true);
      },
      onSetErrorAlertOpen: isOpen => {
        if (!isOpen) {
          setAlertMessage('');
        }
      },
    });
  }, []);

  const handleCreateCampaign = React.useCallback(
    async (params: { url: string; prompt: string; mode: string }) => {
      setNewCampaignPopupOpen(false);
      setLoadingProgressOpen(true);
      try {
        if (!userId) throw new Error('User is not signed in');
        const newCampaignId = await createNewCampaign(userId, params.url, params.prompt, state.creativity, state.outputLanguage, state.session);
        dispatch(resetInputDataSection());
        navigate(`/app/campaigns/${newCampaignId}`);
      } catch (error) {
        console.error('Error creating campaign:', error);
        if (error instanceof Error && error.message.toLowerCase().includes('url')) {
          // If it's a URL-related error, reopen the popup
          setLoadingProgressOpen(false);
          setNewCampaignPopupOpen(true);
        }
        handleError(error);
      } finally {
        setLoadingProgressOpen(false);
      }
    },
    [userId, state.creativity, state.outputLanguage, state.session, dispatch, navigate, handleError]
  );

  function handleAlertPopupSubmit() {
    if (alertMessage === 'Subscription required to create more campaigns') {
      navigate('/app/profile');
    } else {
      handleNewCampaignOpenPopup();
    }
    setAlertPopupOpen(false);
  }

  function getSocialIcons() {
    return (
      isLaptopUp && (
        <Box alignSelf="center" mr={2}>
          <SocialIconButtonComponent icon={Linkedin} callback={trackClickLinkedinHeader} url={'https://www.linkedin.com/company/aiter-io'} />
          <SocialIconButtonComponent icon={Twitter} callback={trackClickTwitterHeader} url={'https://twitter.com/aiter_io'} />
          <SocialIconButtonComponent icon={Facebook} callback={trackClickFacebookHeader} url={'https://www.facebook.com/aiter.tool/'} />
        </Box>
      )
    );
  }

  function getLogo() {
    return (
      <Typography
        variant="h1"
        onClick={() => {
          navigate('/app/campaigns');
        }}
        sx={{
          background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          textFillColor: 'transparent',
          cursor: 'pointer',
          fontFamily: 'Roboto',
        }}>
        aiter
      </Typography>
    );
  }

  function onPricingClick() {
    if (isRegistered) {
      navigate('/app/profile');
    } else {
      setPricingPopupOpen(true);
    }
    handleCloseNavMenu();
  }

  function getMobileView() {
    function getMobileMenuPopup() {
      const triggerSignInWithPopup = async () => {
        if (isRegistered) {
          setIsMenuOpen(false);
          return;
        } else {
          try {
            await registerAsync();
            setIsMenuOpen(false);
          } catch (error) {
            console.error(error);
          }
        }
      };

      return (
        <Box>
          {isCampaignsScreen && (
            <IconButton size="large" onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>
          )}
          {isMenuOpen && (
            <Grid container size={12} justifyContent="space-between" alignItems="center">
              <Grid container size={12} justifyContent="space-between" alignItems="center">
                <Grid alignSelf={'center'} ml={4}>
                  {getLogo()}
                </Grid>
                <Grid>
                  <CloseOutlined
                    color={'disabled'}
                    sx={{
                      cursor: 'pointer',
                      mx: 2,
                      mt: 2,
                      p: 2,
                      width: 64,
                      height: 64,
                    }}
                    onClick={handleCloseNavMenu}
                  />
                </Grid>
              </Grid>
              <Grid size={{ mobile: 12, tablet: 'grow' }} direction="column" gap={1} onClick={handleCloseNavMenu}>
                <BlogMenuItem />
                <Divider sx={{ mx: 2, mt: 4 }} />
              </Grid>
              {pages.map(page => (
                <Grid size={{ mobile: 12, tablet: 'grow' }} direction="column" gap={1} key={page?.title} onClick={() => onMenuItemClick(page)}>
                  <Typography textAlign="center" variant={'h5'} fontWeight={400}>
                    {page?.title}
                  </Typography>
                  <Divider sx={{ mx: 2, mt: 4 }} />
                </Grid>
              ))}
              <Grid size="grow" onClick={onPricingClick}>
                <Typography textAlign="center" variant={'h5'} fontWeight={400}>
                  {t('website.header.pricing')}
                </Typography>
                <Divider sx={{ mx: 2, mt: 4 }} />
              </Grid>
              {!isRegistered && (
                <Grid size="grow" textAlign={'center'}>
                  <Button
                    fullWidth
                    variant={'outlined'}
                    color="primary"
                    size="large"
                    onClick={triggerSignInWithPopup}
                    sx={{
                      border: 1,
                      borderColor: 'primary.main',
                      borderRadius: 8,
                    }}>
                    {t('app.buttons.resultsButton')}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      );
    }

    return (
      <Grid container size={12} justifyContent={'space-evenly'}>
        <Grid size="grow">{getMobileMenuPopup()}</Grid>
        <Grid size="grow" alignSelf="center" textAlign="center">
          {getLogo()}
        </Grid>
        {!isProfileScreen && <RequestsCounter />}
        <Grid alignSelf={'center'}>
          <LanguageSwitcher />
        </Grid>
        <GoogleAuthProfilePicButton />
      </Grid>
    );
  }

  function getDesktopView() {
    return (
      <Grid container size={12} sx={{ width: '100%' }}>
        <Grid alignSelf={'center'}>{getLogo()}</Grid>
        <Grid container size="grow" justifyContent="right" gap={1}>
          <Grid container alignSelf="center" justifyContent="left" size={'grow'} sx={{ mr: isLaptopUp ? 0 : -12, ml: 8 }} gap={1}>
            <Grid>
              <BlogMenuItem />
            </Grid>
            {(isCampaignsScreen || isCampaignDetailsScreen) && (
              <Grid>
                <HeaderButton key={'pricing'} onClick={onPricingClick}>
                  {t('website.header.pricing')}
                </HeaderButton>
              </Grid>
            )}
            <Grid>
              <HeaderButton
                onClick={() => navigate('/app/campaigns')}
                sx={{
                  '&:hover': { color: 'primary.main' },
                }}>
                {t('app.buttons.myCampaigns')}
              </HeaderButton>
            </Grid>
          </Grid>
          <Grid alignSelf="center" textAlign="center">
            {isProfileScreen || !isRegistered ? <></> : <RequestsCounter />}
          </Grid>
          {!isRegistered && (
            <Grid alignSelf="center" textAlign="center">
              {getSocialIcons()}
            </Grid>
          )}
          {(isCampaignDetailsScreen || isProfileScreen) && !isProfileScreen && (
            <Grid alignSelf="center" justifyContent="left">
              <GradientButton version="outlined" buttonText={t('app.buttons.newCampaign')} iconLeft={<IconAddCircle />} onSubmitButtonHandler={onCreateNewCampaignButtonHandler} />
            </Grid>
          )}
          <Grid alignSelf={'center'}>
            <GoogleAuthProfilePicButton />
          </Grid>
          <Grid alignSelf={'center'}>
            <LanguageSwitcher />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <AppBar position="sticky" sx={{ bgcolor: 'white' }} elevation={1}>
      <Container maxWidth="desktop" sx={{ px: '16px! important' }}>
        <Toolbar disableGutters style={{ height: 64 }}>
          {isTabletUp ? getDesktopView() : getMobileView()}
        </Toolbar>
      </Container>
      <NewCampaignPopup open={isNewCampaignPopupOpen} onClose={handleNewCampaignClosePopup} onSubmit={handleCreateCampaign} />
      <LoadingPopup open={isLoadingProgressOpen} />
      <AlertPopup
        open={isAlertPopupOpen}
        onClose={handleAlertClosePopup}
        onSubmit={handleAlertPopupSubmit}
        buttonText={t('app.buttons.ok')}
        alertText={alertMessage}
        title={t('app.popups.error.title')}
      />
      <PricingPopupNonAuthUsers open={isPricingPopupOpen} onClose={() => setPricingPopupOpen(false)} />
      <UpgradePopup open={isUpgradePopupOpen} onClose={() => setUpgradePopupOpen(false)} onError={handleError} />
    </AppBar>
  );
}
