import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import animationData from '../../icons/lottie-update-rocket.json';
import UpdatesBannerComponent from './UpdatesBanner';

const BANNER_NAME = 'claude_3_5_sonnet_launch';
const REDIRECT_URL = 'https://x.com/aiter_io/status/1891487738517488112';

const image = (
  <Lottie
    options={{
      loop: true,
      autoplay: true,
      animationData: animationData,
    }}
    height={120}
    width={120}
  />
);

export default function ClaudeSonnetLaunchBanner() {
  const { t } = useTranslation();

  return (
    <UpdatesBannerComponent
      bannerName={BANNER_NAME}
      redirectUrl={REDIRECT_URL}
      title={t('app.banners.claude_3_5_sonnet_launch.title')}
      description={t('app.banners.claude_3_5_sonnet_launch.description')}
      image={image}
    />
  );
}
