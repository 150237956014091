import { Person } from '@mui/icons-material';
import { Box, Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserPlan } from '../utils/db';
import { RootState } from '../utils/store';

export default function ThankYouPageScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isTabletUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'));

  const state = useSelector((state: RootState) => state.appState);
  const planCode = state?.subcription?.planCode;
  const { plan, loading: planLoading } = useUserPlan(planCode);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}>
      <Grid container justifyContent="center" textAlign="center" gap={4}>
        <Grid size={12}>
          <Typography variant={isTabletUp ? 'h3' : 'h5'}>{t('website.thankyou.title')}</Typography>
          <br />
          <Typography variant={isTabletUp ? 'h4' : 'h6'} color={blue[700]}>
            <b>{plan?.name}</b> — {plan?.price}$
          </Typography>
        </Grid>
        <Grid>
          <Button variant="contained" color="success" startIcon={<Person />} onClick={() => navigate('/app/profile')}>
            {t('website.thankyou.button')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
