import Grid from '@mui/material/Grid'; // Updated import
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCreativity, setManualDescription, setMode, setOutputLanguage } from '../utils/state';
import { RootState } from '../utils/store';
import { Creativity, Mode, OutputLanguageData } from '../utils/types';
import { CreativityAndOutputLanguageSection } from './CreativityAndOutputLanguageSection';
import { ManualModeComponent } from './ManualModeComponent';
import { SwitchComponent } from './SwitchComponent';
import { UrlModeComponent } from './UrlModeComponent';

interface InputDataSectionProps {
  isMoreOptionsForceExpanded: boolean;
  popupMode?: 'new_campaign' | 'regenerate';
  urlError?: string;
  descriptionError?: string;
  onUrlChange?: (url: string) => void;
}

export const InputDataSection: React.FC<InputDataSectionProps> = ({ isMoreOptionsForceExpanded = false, popupMode = 'new_campaign', urlError, descriptionError, onUrlChange }) => {
  const dispatch = useDispatch();

  const { mode: currentMode, url, prompt: summary, creativity, outputLanguage } = useSelector((state: RootState) => state.appState);

  const [isMoreOptionsExpanded, setMoreOptionsExpanded] = React.useState<boolean>(isMoreOptionsForceExpanded);

  const handleCreativityChange = (newCreativity: Creativity) => {
    dispatch(setCreativity(newCreativity));
  };

  const handleOutputLanguageChange = (newLanguage: OutputLanguageData) => {
    dispatch(setOutputLanguage(newLanguage));
  };

  const toggleMoreOptions = () => {
    setMoreOptionsExpanded(!isMoreOptionsExpanded);
  };

  const handleUrlChange = (newUrl: string) => {
    onUrlChange?.(newUrl);
  };

  const onManualInputDescriptionChangeHandler = (newDescription: string) => {
    dispatch(setManualDescription(newDescription));
  };

  const getCreativityAndOutputLanguageSection = () => (
    <CreativityAndOutputLanguageSection
      creativity={creativity}
      outputLanguage={outputLanguage}
      handleCreativityChange={handleCreativityChange}
      handleOutputLanguageChange={handleOutputLanguageChange}
    />
  );

  const handleModeChange = (newMode: Mode) => {
    dispatch(setMode(newMode));
  };

  return (
    <Grid container size={12} gap={2}>
      {popupMode === 'new_campaign' && (
        <Grid size={12}>
          <SwitchComponent mode={currentMode} onModeChanged={handleModeChange} />
        </Grid>
      )}
      {popupMode === 'regenerate' ? (
        <CreativityAndOutputLanguageSection
          creativity={creativity}
          outputLanguage={outputLanguage}
          handleCreativityChange={handleCreativityChange}
          handleOutputLanguageChange={handleOutputLanguageChange}
          mode="regenerate"
        />
      ) : (
        <>
          {currentMode === 'url' && (
            <UrlModeComponent
              url={url}
              setUrl={handleUrlChange}
              isMoreOptionsExpanded={isMoreOptionsExpanded}
              toggleMoreOptions={toggleMoreOptions}
              getCreativityAndOutputLanguageSection={getCreativityAndOutputLanguageSection}
              isMoreOptionsForceExpanded={isMoreOptionsForceExpanded}
              error={urlError || ''}
            />
          )}
          {currentMode === 'manual' && (
            <ManualModeComponent
              manualDescription={summary}
              onManualInputDescriptionChangeHandler={onManualInputDescriptionChangeHandler}
              getCreativityAndOutputLanguageSection={getCreativityAndOutputLanguageSection}
              error={descriptionError || ''}
            />
          )}
        </>
      )}
    </Grid>
  );
};
