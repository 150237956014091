export const getTranslationKey = (key: string): string => {
  // Map of special cases to their exact translation keys
  const specialCases: Record<string, string> = {
    "Needs/JTBD": "NeedsJTBD",
    "Customer Persona": "Persona",
    "VP Canvas": "ValuePropositionCanvas",
    "Value Proposition": "ValueProposition",
    "Business Model": "BusinessModel",
    "Decision Journey": "DecisionJourney",
    "Common Keywords": "CommonKeywords",
    "Long-tail Keywords": "LongTailKeywords",
    "Meta Data": "MetaTitleDescription",
    "Email Subject": "EmailSubject",
    "Promotional Email": "PromotionalEmail",
    "Newsletter Topics": "NewsletterTopics",
    "Content Plan": "ContentPlan",
    "Promo Article": "PromoArticle",
    "Semantic Core": "SemanticCore",
    "Brand Guide": "BrandGuide",
    "Campaign Ideas": "CampaignIdeas",
    "Guest Article": "GuestArticle",
    "PR Article Ideas": "PRArticleIdeas",
    "Marketing Ideas": "MarketingIdeas",
    "Frameworks & Ideas": "FrameworksAndIdeas",
    "7P": "SevenP",
    "Porters Five Forces": "PortersFiveForces",
    "Lean Analytics Stages": "LeanAnalyticsStages",
    "YouTube Ad Script": "YouTubeAdScript",
    "Titles Descriptions": "TitlesDescriptions",
    "Instagram Reel": "InstagramReel",
    "Google Ads": "GoogleAds",
    "TikTok Ad": "TikTokAd",
    "Educational Article": "EducationalArticle",
    "List of Media to Use for Promo": "ListOfMediaToUseForPromo",
  };

  // Return the mapped key if it exists in special cases
  if (key in specialCases) {
    return specialCases[key];
  }

  // For any other keys, return as is
  return key;
};
