import { Button, FormControl, FormHelperText, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconTune } from './minicomponents/icons';

interface UrlModeComponentProps {
  url: string;
  setUrl: (url: string) => void;
  isMoreOptionsExpanded: boolean;
  toggleMoreOptions: () => void;
  getCreativityAndOutputLanguageSection: () => React.ReactNode;
  isMoreOptionsForceExpanded: boolean;
  error: string;
}

export const UrlModeComponent = ({ url, setUrl, isMoreOptionsExpanded, toggleMoreOptions, getCreativityAndOutputLanguageSection, isMoreOptionsForceExpanded, error }: UrlModeComponentProps) => {
  const { t } = useTranslation();

  return (
    <Grid container size={12} gap={1} justifyContent={'center'}>
      <Grid container size={12} gap={2} justifyContent={'center'}>
        <Grid size={12}>
          <FormControl
            variant={'outlined'}
            size={'small'}
            fullWidth
            error={!!error}
            sx={{
              '& .MuiOutlinedInput-input': {
                pl: 2,
              },
            }}>
            <OutlinedInput
              name={t('app.labels.enterWebsiteUrl')}
              placeholder={t('app.labels.websiteUrlPlaceholder')}
              endAdornment={
                !isMoreOptionsForceExpanded && (
                  <InputAdornment position="end" sx={{ mr: -1 }}>
                    <Button
                      onClick={toggleMoreOptions}
                      color="primary"
                      size="small"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'none',
                      }}>
                      <Typography variant={'body1'} color={'primary.main'}>
                        {t('app.labels.moreOptions')}
                      </Typography>
                      <IconTune sx={{ ml: 1 }} />
                    </Button>
                  </InputAdornment>
                )
              }
              value={url}
              onChange={e => {
                setUrl(e.target.value);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              error={!!error}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        </Grid>
        {(isMoreOptionsForceExpanded || isMoreOptionsExpanded) && <Grid container>{getCreativityAndOutputLanguageSection()}</Grid>}
      </Grid>
    </Grid>
  );
};
