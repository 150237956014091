import { Container, Fade, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ClaudeSonnetLaunchBanner from '../components/banners/ClaudeSonnetLaunchBanner';
import CampaignListItem from '../components/CampaignListItem';
import { GradientButton } from '../components/minicomponents/GradientButton';
import { IconAddCircle } from '../components/minicomponents/icons';
import AlertPopup from '../components/popups/AlertPopup';
import LoadingPopup from '../components/popups/LoadingPopup';
import NewCampaignPopup from '../components/popups/NewCampaignPopup';
import UpgradePopup from '../components/popups/UpgradePopup';
import { createNewCampaign, handleApiError as handleApiErrorUtil } from '../utils/api';
import { ANIMATION_DURATION_MS } from '../utils/const';
import { useListenUserCampaigns } from '../utils/db';
import { useFirebaseLoginState } from '../utils/hooks';
import { resetInputDataSection, setManualDescription, setUrl } from '../utils/state';
import { RootState } from '../utils/store';

const EmptyCampaignsList = ({ onCreateNewCampaignButtonHandler }: { onCreateNewCampaignButtonHandler: () => void }) => {
  const { t } = useTranslation();
  return (
    <Grid container size={12} textAlign="center" sx={{ height: '30vh' }} justifyContent="center" alignItems="center">
      <Grid size={12}>
        <Typography variant="h1" sx={{ mb: 3, color: 'text.secondary' }}>
          {t('app.screens.campaigns.noCampaigns')}
        </Typography>
        <GradientButton version="contained" buttonText={t('app.buttons.newCampaign')} iconLeft={<IconAddCircle />} onSubmitButtonHandler={onCreateNewCampaignButtonHandler} />
        <Typography variant="body1" sx={{ mt: 2, color: 'text.secondary' }}>
          {t('app.screens.campaigns.createCampaignHint')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const CampaignsListScreen: React.FC = () => {
  const [isNewCampaignPopupOpen, setNewCampaignPopupOpen] = React.useState(false);
  const [isLoadingProgressOpen, setLoadingProgressOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = useSelector((state: RootState) => state.appState);
  const [isUpgradePopupOpen, setUpgradePopupOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { userId } = useFirebaseLoginState();
  const { campaigns, loading, error: campaignsError } = useListenUserCampaigns(userId);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isCreateNewCampaign = searchParams.get('is_create_new_campaign') === 'true';
    const urlParam = searchParams.get('url');
    const manualDescriptionParam = searchParams.get('manual_description');

    if (isCreateNewCampaign && (urlParam || manualDescriptionParam)) {
      dispatch(setUrl(urlParam || ''));
      dispatch(setManualDescription(manualDescriptionParam || ''));

      if (userId && state.creativity && state.outputLanguage && state.session) {
        handleCreateCampaign({
          url: urlParam || '',
          prompt: manualDescriptionParam || '',
          mode: 'url',
        });
      }
    }
  }, [location, userId, state.creativity, state.outputLanguage, state.session]);

  const handleNewCampaignOpenPopup = () => {
    setNewCampaignPopupOpen(true);
  };

  const handleNewCampaignClosePopup = () => {
    setNewCampaignPopupOpen(false);
  };

  const onCreateNewCampaignButtonHandler = () => {
    handleNewCampaignOpenPopup();
  };

  const handleError = useCallback((error: unknown) => {
    handleApiErrorUtil({
      error,
      onSetUpgradePopupOpen: setUpgradePopupOpen,
      onSetErrorMessage: setError,
      onSetErrorAlertOpen: isOpen => {
        setIsAlertOpen(isOpen);
        if (!isOpen) setError(null);
      },
    });
  }, []);

  const handleAlertSubmit = () => {
    setIsAlertOpen(false);
    handleNewCampaignOpenPopup();
  };

  const handleCreateCampaign = React.useCallback(
    async (params: { url: string; prompt: string; mode: string }) => {
      setNewCampaignPopupOpen(false);
      setLoadingProgressOpen(true);

      try {
        if (!userId) {
          throw new Error('User is not signed in');
        }

        const newCampaignId = await createNewCampaign(userId, params.url, params.prompt, state.creativity, state.outputLanguage, state.session);

        dispatch(resetInputDataSection());
        navigate(`/app/campaigns/${newCampaignId}`);
      } catch (error) {
        console.error('Error creating campaign:', error);
        if (error instanceof Error && error.message.toLowerCase().includes('url')) {
          setLoadingProgressOpen(false);
          setNewCampaignPopupOpen(true);
        }
        handleError(error);
      } finally {
        setLoadingProgressOpen(false);
      }
    },
    [userId, state.creativity, state.outputLanguage, state.session, dispatch, navigate, handleError]
  );

  return (
    <Container maxWidth="desktop" sx={{ pt: 3 }}>
      <ClaudeSonnetLaunchBanner />
      <Grid container gap={3}>
        <Grid size={12} container justifyContent="space-between" alignItems="center">
          <Grid>
            <Fade in={true} timeout={ANIMATION_DURATION_MS}>
              <Typography variant="h1" fontSize={28}>
                {t('app.screens.campaigns.title')}
              </Typography>
            </Fade>
          </Grid>
          {userId !== null && (userId === '' || campaigns.length > 0) && (
            <Grid>
              <GradientButton version="contained" buttonText={t('app.buttons.newCampaign')} iconLeft={<IconAddCircle />} onSubmitButtonHandler={onCreateNewCampaignButtonHandler} />
            </Grid>
          )}
        </Grid>

        {loading && userId !== null ? (
          <> </>
        ) : campaignsError ? (
          <Typography color="error">{t('app.errors.loadingCampaigns', { message: campaignsError.message })}</Typography>
        ) : campaigns.length > 0 ? (
          <Grid container gap={2} size={12}>
            {campaigns.map(campaign => (
              <CampaignListItem key={campaign.id} campaign={campaign} />
            ))}
          </Grid>
        ) : (
          <EmptyCampaignsList onCreateNewCampaignButtonHandler={onCreateNewCampaignButtonHandler} />
        )}
      </Grid>
      <NewCampaignPopup open={isNewCampaignPopupOpen} onClose={handleNewCampaignClosePopup} onSubmit={handleCreateCampaign} />
      <LoadingPopup open={isLoadingProgressOpen} />
      <AlertPopup open={isAlertOpen} onClose={() => setIsAlertOpen(false)} onSubmit={handleAlertSubmit} alertText={error || ''} title={t('app.popups.error.title')} buttonText={t('app.buttons.ok')} />
      <UpgradePopup open={isUpgradePopupOpen} onClose={() => setUpgradePopupOpen(false)} onError={handleError} />
    </Container>
  );
};
