import { Box, Button, Card, CardContent, Dialog, DialogContent, Divider, IconButton, Theme, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { registerAsync } from '../../utils/auth';
import { IconClose } from '../minicomponents/icons';

interface PlanFeature {
  text: string;
}

interface PricingPlan {
  title: string;
  price: string;
  description: string;
  features: PlanFeature[];
  isHighlighted?: boolean;
}

interface PricingOptionProps extends PricingPlan {
  buttonText: string;
  onButtonClick: () => Promise<void>;
  isMonthly: boolean;
}

const PricingOption = ({ title, price, description, features, buttonText, isHighlighted, onButtonClick, isMonthly }: PricingOptionProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...(isHighlighted && {
          border: `2px solid ${theme.palette.primary.main}`,
          position: 'relative',
        }),
      }}>
      {isHighlighted && isMonthly && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '0.75rem',
            textAlign: 'center',
            width: '100%',
          }}>
          {t('website.pricingTable.bestDeal')}
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          p: 2,
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h3" component="div" sx={{ mb: 2, textAlign: 'center' }}>
          ${price}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {description}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}>
          {features.map((feature, index) => (
            <Typography key={index} variant="body2" sx={{ mb: 1 }}>
              ✓ {feature.text}
            </Typography>
          ))}
        </Box>
        <Button
          fullWidth
          variant={isHighlighted ? 'contained' : 'outlined'}
          color={isHighlighted ? 'primary' : 'inherit'}
          sx={{
            mt: 2,
            backgroundColor: isHighlighted ? theme.palette.primary.main : theme.palette.primary.light,
            color: isHighlighted ? 'white' : theme.palette.primary.main,
            '&:hover': {
              backgroundColor: isHighlighted ? theme.palette.primary.dark : theme.palette.primary.main,
              color: 'white',
            },
          }}
          onClick={onButtonClick}>
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
};

interface PricingPopupNonAuthUsersProps {
  open: boolean;
  onClose: () => void;
}

export const PricingPopupNonAuthUsers: React.FC<PricingPopupNonAuthUsersProps> = ({ open, onClose }) => {
  const [pricingPeriod, setPricingPeriod] = useState<'monthly' | 'yearly' | 'oneTime'>('monthly');
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const isLaptopUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('laptop'));

  const PRICING_DATA: Record<'monthly' | 'yearly' | 'oneTime', PricingPlan[]> = {
    monthly: [
      {
        title: t('website.pricingTable.plans.begin.title'),
        price: '9.99',
        description: t('website.pricingTable.plans.begin.description'),
        features: [
          { text: t('website.pricingTable.plans.begin.features.credits') },
          { text: t('website.pricingTable.plans.begin.features.creativityLevels') },
          { text: t('website.pricingTable.plans.begin.features.support') },
          { text: t('website.pricingTable.plans.begin.features.regenerate') },
        ],
      },
      {
        title: t('website.pricingTable.plans.advance.title'),
        price: '29.99',
        description: t('website.pricingTable.plans.advance.description'),
        features: [
          { text: t('website.pricingTable.plans.advance.features.credits') },
          { text: t('website.pricingTable.plans.advance.features.fromBegin') },
          { text: t('website.pricingTable.plans.advance.features.strategy') },
          { text: t('website.pricingTable.plans.advance.features.frameworks') },
        ],
        isHighlighted: true,
      },
      {
        title: t('website.pricingTable.plans.conquer.title'),
        price: '149',
        description: t('website.pricingTable.plans.conquer.description'),
        features: [
          { text: t('website.pricingTable.plans.conquer.features.credits') },
          { text: t('website.pricingTable.plans.conquer.features.fromAdvance') },
          { text: t('website.pricingTable.plans.conquer.features.consultation') },
        ],
      },
    ],
    yearly: [
      {
        title: t('website.pricingTable.plans.begin.title'),
        price: '79',
        description: t('website.pricingTable.plans.begin.description'),
        features: [
          { text: t('website.pricingTable.plans.begin.features.credits') },
          { text: t('website.pricingTable.plans.begin.features.creativityLevels') },
          { text: t('website.pricingTable.plans.begin.features.support') },
          { text: t('website.pricingTable.plans.begin.features.regenerate') },
        ],
      },
      {
        title: t('website.pricingTable.plans.advance.title'),
        price: '269',
        description: t('website.pricingTable.plans.advance.description'),
        features: [
          { text: t('website.pricingTable.plans.advance.features.credits') },
          { text: t('website.pricingTable.plans.advance.features.fromBegin') },
          { text: t('website.pricingTable.plans.advance.features.strategy') },
          { text: t('website.pricingTable.plans.advance.features.frameworks') },
        ],
        isHighlighted: true,
      },
      {
        title: t('website.pricingTable.plans.conquer.title'),
        price: '1,499',
        description: t('website.pricingTable.plans.conquer.description'),
        features: [
          { text: t('website.pricingTable.plans.conquer.features.credits') },
          { text: t('website.pricingTable.plans.conquer.features.fromAdvance') },
          { text: t('website.pricingTable.plans.conquer.features.consultation') },
        ],
      },
    ],
    oneTime: [
      {
        title: t('website.pricingTable.plans.begin.title'),
        price: '179',
        description: t('website.pricingTable.plans.begin.description'),
        features: [
          { text: t('website.pricingTable.plans.begin.features.credits') },
          { text: t('website.pricingTable.plans.begin.features.creativityLevels') },
          { text: t('website.pricingTable.plans.begin.features.support') },
          { text: t('website.pricingTable.plans.begin.features.regenerate') },
        ],
      },
      {
        title: t('website.pricingTable.plans.advance.title'),
        price: '599',
        description: t('website.pricingTable.plans.advance.description'),
        features: [
          { text: t('website.pricingTable.plans.advance.features.credits') },
          { text: t('website.pricingTable.plans.advance.features.fromBegin') },
          { text: t('website.pricingTable.plans.advance.features.strategy') },
          { text: t('website.pricingTable.plans.advance.features.frameworks') },
        ],
        isHighlighted: true,
      },
      {
        title: t('website.pricingTable.plans.conquer.title'),
        price: '1,999',
        description: t('website.pricingTable.plans.conquer.description'),
        features: [
          { text: t('website.pricingTable.plans.conquer.features.credits') },
          { text: t('website.pricingTable.plans.conquer.features.fromAdvance') },
          { text: t('website.pricingTable.plans.conquer.features.consultation') },
        ],
      },
    ],
  };

  const handlePricingPeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: 'monthly' | 'yearly' | 'oneTime' | null) => {
    if (newPeriod !== null) {
      setPricingPeriod(newPeriod);
    }
  };

  const onButtonClicked = async () => {
    try {
      await registerAsync();
      onClose();
      navigate('/app/profile');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="laptop"
      fullWidth
      PaperProps={{
        component: motion.div,
        initial: { y: '100%' },
        animate: { y: 0 },
        exit: { y: '100%' },
        transition: { type: 'spring', stiffness: 300, damping: 30 },
        sx: {
          marginTop: '52px',
          marginBottom: '52px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}>
      <Grid container size={12} sx={{ p: 3, pb: 2, alignItems: 'center' }}>
        <Grid size={'grow'}>
          <Typography variant="h5">Pricing</Typography>
        </Grid>
        <Grid size={'auto'} justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <IconClose />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <DialogContent>
        <Box sx={{ width: '100%', py: 2 }}>
          <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <ToggleButtonGroup value={pricingPeriod} exclusive onChange={handlePricingPeriodChange} aria-label="pricing period">
                {['monthly', 'yearly', 'oneTime'].map(period => (
                  <ToggleButton
                    key={period}
                    value={period}
                    aria-label={period}
                    sx={{
                      '&.Mui-selected': {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.light,
                      },
                    }}>
                    {period.toUpperCase()}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <AnimatePresence mode="wait">
              <motion.div key={pricingPeriod} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isLaptopUp ? 'row' : 'column',
                    gap: isLaptopUp ? 2 : 0,
                  }}>
                  {PRICING_DATA[pricingPeriod].map(plan => (
                    <Box key={plan.title} sx={{ flex: 1, mb: isLaptopUp ? 0 : 2 }}>
                      <PricingOption {...plan} buttonText="SUBSCRIBE" onButtonClick={onButtonClicked} isMonthly={pricingPeriod === 'monthly'} />
                    </Box>
                  ))}
                </Box>
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
