import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid'; // Updated import
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setManualDescription, setUrl } from '../../utils/state';
import { RootState, store } from '../../utils/store';
import { InputDataSection } from '../InputDataSection';
import { GradientButton } from '../minicomponents/GradientButton';
import { IconClose } from '../minicomponents/icons';

const NewCampaignPopup = ({ open, onClose, onSubmit }: { open: boolean; onClose: () => void; onSubmit: (params: { url: string; prompt: string; mode: string }) => void }) => {
  const { t } = useTranslation();

  const { mode: currentMode, url, prompt } = useSelector((state: RootState) => state.appState);

  const dispatch = useDispatch();
  const [urlError, setUrlError] = useState<string>();
  const [descriptionError, setDescriptionError] = useState<string>();

  const validateUrl = (url: string): string => {
    if (!url) {
      return 'URL is required';
    }
    if (url.length < 3) {
      return 'URL must be at least 3 characters long';
    }
    return '';
  };

  const validateDescription = (description: string): string => {
    if (!description) {
      return 'Description is required';
    }
    if (description.length <= 5) {
      return 'Description must be longer than 5 characters';
    }
    return '';
  };

  const handleUrlChange = (newUrl: string) => {
    dispatch(setUrl(newUrl));
    setUrlError(''); // Clear any previous error on typing
  };

  const onSubmitHandler = () => {
    // Get the latest state right before validation
    const currentState = store.getState().appState;
    const currentUrl = currentState.url;
    const currentPrompt = currentState.prompt;

    let isValid = false;
    if (currentMode === 'url') {
      const error = validateUrl(currentUrl);
      setUrlError(error);
      setDescriptionError('');
      isValid = !error;
    } else {
      const error = validateDescription(currentPrompt);
      setUrlError('');
      setDescriptionError(error);
      isValid = !error;
    }

    if (isValid) {
      // Clear the opposite mode's data before submitting
      if (currentMode === 'url') {
        dispatch(setManualDescription(''));
      } else {
        dispatch(setUrl(''));
      }
      // Wait for state updates to complete before submitting
      setTimeout(() => {
        const finalState = store.getState().appState;
        onSubmit({
          mode: currentMode,
          url: finalState.url || '',
          prompt: finalState.prompt || '',
        });
      }, 0);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onSubmitHandler();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: motion.div,
        initial: { y: '100%' },
        animate: { y: 0 },
        exit: { y: '100%' },
        transition: { type: 'spring', stiffness: 300, damping: 30 },
        sx: {
          maxHeight: 'calc(100vh - 104px)', // 52px top + 52px bottom margins
          marginTop: '52px',
          marginBottom: '52px',
          display: 'flex',
          flexDirection: 'column',
          width: 672,
        },
      }}>
      <Grid container size={12} sx={{ p: 3, pb: 2, alignItems: 'center' }}>
        <Grid size={'grow'}>
          <Typography variant="h5">{t('website.popupNewCampaign.title')}</Typography>
        </Grid>
        <Grid size={'auto'} justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <IconClose />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <DialogContent sx={{ flex: 1, overflowY: 'auto' }}>
        <InputDataSection isMoreOptionsForceExpanded={true} urlError={urlError} descriptionError={descriptionError} onUrlChange={handleUrlChange} />
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2, gap: 1 }}>
        <GradientButton buttonText={t('website.popupNewCampaign.buttonGenerate')} sx={{ width: 200 }} onSubmitButtonHandler={onSubmitHandler} />
        <Button onClick={onClose}>{t('app.buttons.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewCampaignPopup;
