import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import imageManRegister from '../../icons/man-will-smith-register.webp';

interface RegisterPopupDialogProps {
  open: boolean;
  onClose: () => void;
  onRegisterClicked: () => void;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  '&::before': {
    content: '"•"',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const RegisterPopup: React.FC<RegisterPopupDialogProps> = ({ open, onClose, onRegisterClicked }) => {
  const { t } = useTranslation();
  const benefits = [t('website.registerPopup.benefits.credits'), t('website.registerPopup.benefits.history'), t('website.registerPopup.benefits.tasks')];

  return (
    <Dialog
      PaperProps={{
        component: motion.div,
        initial: { y: '100%' },
        animate: { y: 0 },
        exit: { y: '100%' },
        transition: { type: 'spring', stiffness: 300, damping: 30 },
        sx: {
          maxHeight: 'calc(100vh - 104px)',
          marginTop: '52px',
          marginBottom: '52px',
          display: 'flex',
          flexDirection: 'column',
          width: 672,
        },
      }}
      open={open}
      onClose={onClose}
      maxWidth="tablet"
      fullWidth>
      <DialogTitle>
        <Typography variant="h2">{t('website.registerPopup.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container size={{ mobile: 12, tablet: 6 }}>
          <Grid>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('website.registerPopup.description1')}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('website.registerPopup.description2')}
            </Typography>
          </Grid>
          <Grid container>
            <Grid size={'grow'}>
              <Typography variant="h6" fontWeight="bold">
                {t('website.registerPopup.benefitsTitle')}
              </Typography>
              <List dense disablePadding sx={{ mx: 2 }}>
                {benefits.map((benefit, index) => (
                  <StyledListItem key={index}>
                    <ListItemText primary={benefit} />
                  </StyledListItem>
                ))}
              </List>
            </Grid>
            <Grid size={'auto'}>
              <img src={imageManRegister} alt="Register popup image" width={200} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('website.registerPopup.buttons.cancel')}</Button>
        <Button
          onClick={onRegisterClicked}
          variant="contained"
          color="success"
          sx={{
            color: 'white',
            '&:hover': {
              bgcolor: 'success.dark',
            },
          }}>
          {t('website.registerPopup.buttons.signUp')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterPopup;
