import { Box, Button, ClickAwayListener, ClickAwayListenerProps, Grid, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import React, { forwardRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { colors } from '../../utils/colors';
import { OUTPUT_LANGUAGE_DATA } from '../../utils/const';
import { dateToString, formatDateToExactTime } from '../../utils/dateUtils';
import { InfoTooltipView } from './InfoTooltipView';
import { IconCheck, IconCopy } from './icons';

export const GradientTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  fontWeight: 700,
}));

export const CampaignMetadataSectionComponent: React.FC<{
  createdAt?: Date | null;
  language?: string;
  creativity?: string;
  sx?: SxProps<Theme>;
  isEvenSpacing?: boolean;
}> = ({ createdAt, language, creativity, sx, isEvenSpacing = false }) => {
  return (
    <Grid size={{ mobile: 'grow' }} container gap={isEvenSpacing ? 0 : 4} justifyContent="flex-start" alignItems="center" sx={sx}>
      {createdAt && (
        <Grid container size={{ mobile: isEvenSpacing ? 4 : 'auto' }} alignItems="center">
          <Grid>
            <Typography variant="body2" mr={1}>
              {t('app.labels.generated')}:
            </Typography>
          </Grid>
          <Grid container alignItems="center">
            <InfoTooltipView
              text={formatDateToExactTime(createdAt)}
              icon={
                <Typography variant="body1" color={'text.secondary'} noWrap>
                  {dateToString(createdAt)}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      )}
      {language && (
        <Grid container size={{ mobile: isEvenSpacing ? 4 : 'auto' }}>
          <Grid>
            <Typography variant="body2" mr={1}>
              {t('app.labels.language')}:
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1" color={'text.secondary'} noWrap>
              {OUTPUT_LANGUAGE_DATA.find(l => l.code === language)?.name}
            </Typography>
          </Grid>
        </Grid>
      )}
      {creativity && (
        <Grid container size={{ mobile: isEvenSpacing ? 4 : 'auto' }}>
          <Grid>
            <Typography variant="body2" mr={1}>
              {t('app.labels.creativityLevel')}:
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1" color={'text.secondary'} noWrap>
              {t(`app.labels.${creativity}Creativity`)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

type ReactMarkdownStyledProps = {
  children: string;
  remarkPlugins?: any[];
  rehypePlugins?: any[];
  components?: React.ComponentProps<typeof ReactMarkdown>['components'];
};

const StyledMarkdownWrapper = styled(Box)`
  font-family: 'LabGrotesque-Regular';
  line-height: 20px;
`;

export const ReactMarkdownStyled = forwardRef<HTMLDivElement, ReactMarkdownStyledProps>(({ children, ...props }, ref) => (
  <StyledMarkdownWrapper ref={ref}>
    <ReactMarkdown
      {...props}
      components={{
        ...props.components,
        a: ({ node, ...linkProps }) => <a {...linkProps} target="_blank" rel="noopener noreferrer" />,
      }}>
      {children}
    </ReactMarkdown>
  </StyledMarkdownWrapper>
));

ReactMarkdownStyled.displayName = 'ReactMarkdownStyled';

export const ButtonWithIcon = ({
  icon,
  onClick,
  isAccentOnHover = false,
  isDisabled = false,
  sx,
}: {
  icon: React.ReactElement;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isAccentOnHover?: boolean;
  isDisabled?: boolean;
  sx?: SxProps<Theme>;
}) => (
  <Button
    onClick={onClick}
    disabled={isDisabled}
    sx={{
      height: '32px',
      minWidth: '32px',
      borderRadius: 0,
      opacity: isDisabled ? 0.5 : 1,
      '&:hover': {
        color: isAccentOnHover ? 'primary.main' : 'text.primary',
        bgcolor: colors.custom.hoverCampaignDetailsIcon,
      },
      ...sx,
    }}>
    {icon}
  </Button>
);

export const CopyButton = ({ textToCopy }: { textToCopy: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };

  const iconVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 },
  };

  return (
    <InfoTooltipView
      text={isCopied ? t('app.labels.copied') : undefined}
      forceOpen={isCopied}
      icon={
        <ButtonWithIcon
          icon={
            <AnimatePresence mode="wait">
              {isCopied ? (
                <motion.div key="check" initial="initial" animate="animate" exit="exit" variants={iconVariants} transition={{ duration: 0.2 }}>
                  <IconCheck sx={{ color: green[500], display: 'flex' }} />
                </motion.div>
              ) : (
                <motion.div key="copy" initial="initial" animate="animate" exit="exit" variants={iconVariants} transition={{ duration: 0.2 }}>
                  <IconCopy sx={{ display: 'flex' }} />
                </motion.div>
              )}
            </AnimatePresence>
          }
          onClick={handleCopy}
          isAccentOnHover={false}
        />
      }
    />
  );
};

export const SentryMuiWrapper = React.forwardRef<HTMLElement, ClickAwayListenerProps>((props, ref) => {
  const { 'data-sentry-element': _sentryElement, 'data-sentry-component': _sentryComponent, 'data-sentry-source-file': _sentrySourceFile, ...restProps } = props as any;

  return <ClickAwayListener {...restProps} ref={ref} />;
});
