import { Payment } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Container, Grid, Link, TextField, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PricingPage } from '../components/PricingPage';
import GoogleIcon from '../icons/Google.webp';
import { trackClickLogoutProfile, trackSentryError, trackSignOutProfile, trackStripePurchaseSuccesful } from '../utils/analytics';
import { redeemAppsumoCode } from '../utils/api';
import { getPlanByNumberOfCodesRedeemed } from '../utils/appsumo';
import { signOutUser } from '../utils/auth';
import { STRIPE_URL_MANAGE_PLAN } from '../utils/const';
import { openCrispChat } from '../utils/crispUtils';
import { useUserCodes, useUserPlan } from '../utils/db';
import { useFirebaseLoginState, useIsPaidUserStatus } from '../utils/hooks';
import { RootState } from '../utils/store';

export default function Profile() {
  const state = useSelector((state: RootState) => state.appState);

  const { isRegistered, user, isAuthStateLoading } = useFirebaseLoginState();
  const isPaidUser = useIsPaidUserStatus(state?.subcription?.status);

  const appSumoCodes = useUserCodes(user?.uid);
  const isAppSumoUser = appSumoCodes?.length > 0;
  const codesRedeemed = appSumoCodes?.filter(x => x.isRedeemed);
  const codesRefunded = appSumoCodes?.filter(x => x.isRefunded);
  const codesRedeemedAndNotRefunded = codesRedeemed?.filter(x => !x.isRefunded);
  const codesNotRedeemedAndNotRefunded = appSumoCodes?.filter(x => !x.isRedeemed && !x.isRefunded);
  const appSumoPlan = getPlanByNumberOfCodesRedeemed(codesRedeemedAndNotRefunded?.length);
  const isMaxAppSumoPlanReached = appSumoPlan?.id === 3;

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAuthStateLoading && !isRegistered) {
      navigate('/');
    }
  }, [isRegistered, isAuthStateLoading]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const checkoutSessionId = urlParams.get('checkout_session_id') || undefined;
    if (!!checkoutSessionId) {
      trackStripePurchaseSuccesful(checkoutSessionId);
    } else {
      console.warn('No checkout session id found in url params');
    }
  }, []);

  const confirmLogout = useConfirm();
  const navigate = useNavigate();

  const displayName = user?.providerData[0]?.displayName;
  const userName = displayName?.split(' ')?.[0] ?? '';
  const userSurname = displayName?.split(' ')?.[1] ?? '';
  const userEmail = user?.email ?? '';

  const handleLogoutClick = () => {
    trackClickLogoutProfile();
    confirmLogout({
      title: t('website.profile.confirmLogoutTitle'),
      description: t('website.profile.confirmLogoutMessage'),
    }).then(async () => {
      const userId = user?.uid;
      try {
        await signOutUser();
      } catch (e) {
        console.error('Error signing out [profile]:', e);
      }
      trackSignOutProfile(userId);
      navigate('/');
    });
  };

  function SubscriptionView() {
    function getManageSubscriptionButton() {
      return (
        <Button
          variant="outlined"
          size={'large'}
          sx={{
            border: 1,
            color: isPaidUser ? 'primary.main' : 'success.main',
            borderColor: isPaidUser ? 'primary.main' : 'success.main',
            '&:hover': {
              borderColor: isPaidUser ? 'primary.dark' : 'success.dark',
            },
          }}
          onClick={() => window.open(STRIPE_URL_MANAGE_PLAN, '_blank')}
          startIcon={<Payment />}>
          {isPaidUser ? t('website.profile.manageSubscription') : t('website.profile.renewSubscription')}
        </Button>
      );
    }

    const subscriptionName = state?.stripe?.subscriptionName ?? '';
    const subscriptionCancelAt = state?.stripe?.cancelAt ?? 0;

    // Create date objects - directly parse the ISO strings with moment
    const subscriptionPeriod = {
      startDate: moment(state?.subcription?.dateStart),
      endDate: moment(state?.subcription?.dateEnd),
    };

    const billingCycle = {
      startDate: moment(state?.subcription?.dateCycleStart),
      endDate: moment(state?.subcription?.dateCycleEnd),
    };

    function getSubscriptionDetailsView() {
      // Check if dates are expired by comparing with their actual values, not current time
      const isSubscriptionExpired = subscriptionPeriod.endDate.isValid() && subscriptionPeriod.endDate.isBefore(moment());
      const isBillingCycleExpired = billingCycle.endDate.isValid() && billingCycle.endDate.isBefore(moment());

      // Calculate days left by comparing with the actual end dates
      const billingCycleDaysLeft = billingCycle.endDate.isValid() ? Math.max(0, billingCycle.endDate.diff(moment(), 'days')) : 0;

      return (
        <Typography variant={'body2'}>
          {/* Subscription Period */}
          {t('website.profile.subscriptionDetailsStartDate')} <b>{subscriptionPeriod.startDate.isValid() ? subscriptionPeriod.startDate.format('LLL') : 'Invalid date'}</b>
          {isSubscriptionExpired && (
            <>
              <br />
              {t('website.profile.subscriptionDetailsEndDate')}{' '}
              <b style={{ color: isSubscriptionExpired ? red[500] : 'inherit' }}>{subscriptionPeriod.endDate.isValid() ? subscriptionPeriod.endDate.format('LLL') : 'Invalid date'}</b>
            </>
          )}
          <br />
          <br />
          {/* Billing Cycle - where credits reset */}
          {t('website.profile.subscriptionDetailsBillingCycleStart')} <b>{billingCycle.startDate.isValid() ? billingCycle.startDate.format('LLL') : 'Invalid date'}</b>
          <br />
          {t('website.profile.subscriptionDetailsBillingCycleEnd')}{' '}
          <b style={{ color: isBillingCycleExpired ? red[500] : 'inherit' }}>{billingCycle.endDate.isValid() ? billingCycle.endDate.format('LLL') : 'Invalid date'}</b>
          <br />
          {t('website.profile.subscriptionDetailsBillingCycleDaysLeft')} <b>{billingCycle.endDate.isValid() ? billingCycleDaysLeft : 'N/A'}</b>
        </Typography>
      );
    }

    function getActiveSubscriptionView() {
      const planCode = state?.subcription?.planCode;
      const { plan, loading: planLoading } = useUserPlan(planCode);

      return (
        <Grid container direction={'column'} gap={2} sx={{ mt: 0.1 }}>
          <Grid>
            <Typography variant={'body1'}>
              {t('website.profile.subscriptionDetailsActiveSubscription')} <b>{planLoading ? '...' : plan?.name || t('website.profile.unknownPlan')}</b>
            </Typography>
          </Grid>
          {getSubscriptionDetailsView()}
          <Grid>
            <Typography variant={'body2'}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('website.profile.subscriptionDetailsCreditsLeft', {
                    creditsLeft: state?.subcription?.credits ?? 0,
                  }),
                }}
              />
            </Typography>
          </Grid>
          <Grid>
            <Typography variant={'body2'} color={'success.main'}>
              {t('website.profile.subscriptionDetailsUpdatePlan')}
            </Typography>
          </Grid>
          <Grid size={12}>{getManageSubscriptionButton()}</Grid>
        </Grid>
      );
    }

    function getActiveLimitedSubscriptionView() {
      const isLimitedByCredits = !creditsLeft || creditsLeft <= 0;
      return (
        <Grid container direction={'column'} spacing={2} sx={{ mt: -0.4 }}>
          <Grid>
            <Typography variant={'body1'}>
              {isLimitedByCredits ? (
                <>
                  {t('website.profile.subscriptionDetailsActiveSubscription')} <b>{subscriptionName}</b>
                </>
              ) : (
                <>
                  {t('website.profile.subscriptionDetailsSubscription')} <b>{subscriptionName}</b>
                </>
              )}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant={'body2'}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('website.profile.subscriptionDetailsFinishedSubscription', { creditsLeft }),
                }}
              />
            </Typography>
          </Grid>
          {getSubscriptionDetailsView()}
          <Grid>
            <Typography variant={'body2'} color={'success.main'}>
              {t('website.profile.subscriptionDetailsUpdateToContinueUsing')}
            </Typography>
          </Grid>
          <Grid size={12}>{getManageSubscriptionButton()}</Grid>
        </Grid>
      );
    }

    function getCanceledSubscriptionView() {
      const subscriptionCancelAtMoment = moment(state?.stripe?.cancelAt);
      const isCancelationInFuture = state?.stripe?.cancelAt && subscriptionCancelAtMoment.isAfter(moment());
      const cancelAtFormattedString = state?.stripe?.cancelAt && subscriptionCancelAtMoment.format('LLL');
      return (
        <Grid container direction={'column'} spacing={2} size={12} sx={{ mt: -0.4 }}>
          <Grid>
            <Typography variant={'body1'}>
              <div
                dangerouslySetInnerHTML={{
                  __html: isCancelationInFuture
                    ? t('website.reqLeftCounter.canceledActive.futureCancelation.title', { cancelAtFormattedString })
                    : t('website.reqLeftCounter.canceledActive.pastCancelation.title', { cancelAtFormattedString }),
                }}
              />
            </Typography>
          </Grid>
          <Grid>
            <Typography variant={'body2'}>
              <div
                dangerouslySetInnerHTML={{
                  __html: isCancelationInFuture
                    ? t('website.reqLeftCounter.canceledActive.futureCancelation.subtitle', { creditsLeft })
                    : t('website.reqLeftCounter.canceledActive.pastCancelation.subtitle', { creditsLeft }),
                }}
              />
            </Typography>
          </Grid>
          <Grid>
            <Typography variant={'body2'} color={'success.main'}>
              {t('website.profile.subscriptionDetailsUpdatePlan')}
            </Typography>
          </Grid>
          <Grid size={12}>{getManageSubscriptionButton()}</Grid>
        </Grid>
      );
    }

    function getCanceledInactiveSubscriptionView() {
      const subscriptionCancelAtMoment = moment(state?.stripe?.cancelAt);
      const isCancelationInFuture = state?.stripe?.cancelAt && subscriptionCancelAtMoment.isAfter(moment());
      const cancelAtFormattedString = state?.stripe?.cancelAt && subscriptionCancelAtMoment.format('LLL');

      return (
        <Grid container direction={'column'} spacing={2} sx={{ mt: -0.3 }}>
          <Grid>
            <Typography variant={'body1'}>
              <div
                dangerouslySetInnerHTML={{
                  __html: isCancelationInFuture
                    ? t('website.reqLeftCounter.canceledActive.futureCancelation.title', { cancelAtFormattedString })
                    : t('website.reqLeftCounter.canceledActive.pastCancelation.title', { cancelAtFormattedString }),
                }}
              />
            </Typography>
          </Grid>
          {getSubscriptionDetailsView()}
          <Grid>
            <Typography variant={'body2'} color={'success.main'}>
              {t('website.profile.subscriptionDetailsUpdatePlan')}
            </Typography>
          </Grid>
          <Grid size={12}>{getManageSubscriptionButton()}</Grid>
        </Grid>
      );
    }

    function getFreemiumSubscriptionView() {
      return (
        <Grid container direction={'column'} spacing={2} sx={{ mt: -0.3 }}>
          <Grid>
            <Typography
              variant={'body1'}
              dangerouslySetInnerHTML={{
                __html: t('website.profile.subscriptionDetailsFreemium', {
                  creditsLeft,
                }),
              }}
            />
          </Grid>
        </Grid>
      );
    }

    const creditsLeft = state?.subcription?.credits ?? 0;

    switch (state?.subcription?.status) {
      case 'paid_active': // subscription is active and not limited
        return getActiveSubscriptionView();
      case 'paid_active_limited': // subscription is active and limited
        return getActiveLimitedSubscriptionView();
      case 'canceled_active': // subscription is canceled but still active
        return getCanceledSubscriptionView();
      case 'canceled_past_due': // subscription is canceled and expired
        return getCanceledInactiveSubscriptionView();
      case 'freemium': // subscription is freemium, showing nothing since already shown
        return getFreemiumSubscriptionView();
      case undefined:
        console.warn('Subscription status is undefined, showing empty pricing table.');
        return <></>;
      default:
        console.error('Unknown subscription status, showing empty pricing table.');
        return <></>;
    }
  }

  function AppSumoSubscriptionView() {
    const theme = useTheme();
    const isTabletUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'));

    const [code, setCode] = React.useState('');
    const [isButtonStateLoading, setIsButtonStateLoading] = React.useState(false);
    const [codeError, setCodeError] = React.useState<string | undefined>();

    const onSubmitCodeClick = () => {
      setIsButtonStateLoading(true);
      setCodeError(undefined);

      redeemAppsumoCode(user?.uid, code)
        .then(() => {
          setIsButtonStateLoading(false);
        })
        .catch(error => {
          trackSentryError(`Error redeeming code`, {
            code: code,
            error: error.message,
          });
          setIsButtonStateLoading(false);
          setCodeError(JSON.parse(error.message).message);
        });
    };

    return (
      <Grid container direction={'column'} spacing={2} sx={{ mt: -0.3 }}>
        <Grid>
          <Typography variant={'body1'}>
            <b>{appSumoPlan?.name}</b> 🚀
          </Typography>
          <Typography variant={'body2'} sx={{ color: 'success.main' }}>
            {t('website.profile.appsumoCodesRedeemed')}: <b>{codesRedeemedAndNotRefunded?.length}</b>
          </Typography>
          {!!codesRefunded?.length && (
            <Typography variant={'body2'} sx={{ color: grey[600] }}>
              {t('website.profile.appsumoCodesRefunded')}: <b>{codesRefunded.length}</b>
            </Typography>
          )}
          {!!codesNotRedeemedAndNotRefunded?.length && (
            <Typography variant={'body2'} sx={{ color: red[300] }}>
              {t('website.profile.appsumoCodesErrors')}: <b>{codesNotRedeemedAndNotRefunded.length}</b>
            </Typography>
          )}
        </Grid>
        {appSumoCodes?.map((code, index) => {
          return (
            <Grid>
              <Typography variant={'body2'}>
                {index + 1}. {t('website.profile.appsumoCode')}: <b>{code.code}</b>
              </Typography>
              <Typography variant={'body2'} sx={{ ml: 2 }} color={code.isRedeemed ? 'success.main' : red[300]}>
                {code.isRedeemed ? (
                  <>
                    {t('website.profile.appsumoCodeRedeemedAt')}: <b>{moment(code.redeemedAt).format('LLL')}</b>
                  </>
                ) : (
                  <>
                    {t('website.profile.appsumoCodeWasNotRedeemedError')} (
                    <Link
                      href="#"
                      onClick={openCrispChat}
                      sx={{
                        color: red[300],
                        textDecoration: 'underline',
                      }}>
                      {t('website.profile.appsumoContactSupport')}
                    </Link>
                    )
                  </>
                )}
              </Typography>
              {code.isRefunded && (
                <Typography variant={'body2'} sx={{ ml: 2 }} color={code.isRefunded ? grey[600] : undefined}>
                  {t('website.profile.appsumoCodeRefundedAt')}: <b>{moment(code.refundedAt).format('LLL')}</b>
                </Typography>
              )}
            </Grid>
          );
        })}
        {!isMaxAppSumoPlanReached && (
          <>
            <Grid size={12}>
              <TextField
                label={t('website.profile.appsumoAppSumoCode')}
                variant="outlined"
                sx={{ minWidth: isTabletUp ? 300 : '100%' }}
                placeholder={t('website.profile.appsumoCodeExample')}
                value={code}
                error={!!codeError}
                helperText={codeError}
                onChange={e => setCode(e.target.value)}
              />
            </Grid>
            <Grid size={12}>
              <LoadingButton
                onClick={onSubmitCodeClick}
                variant="contained"
                color={'success'}
                loading={isButtonStateLoading}
                sx={{
                  ...(isButtonStateLoading && {
                    background: `${green[100]} !important`,
                  }),
                }}>
                {t('website.profile.appsumoSubmitOneMoreCode')}
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
    );
  }

  return (
    <Container>
      {/* <ReleaseNotificationBanner sx={{ mt: 2 }} /> */}
      {!isAuthStateLoading && (
        <>
          <Grid
            container
            justifyContent={'center'}
            gap={4}
            sx={{
              m: { mobile: 2, tablet: 4, desktop: 8 },
              p: { mobile: 0, tablet: 2, desktop: 3 },
            }}>
            <Grid size={12} textAlign={'center'} sx={{ mb: { mobile: 0, tablet: 6 } }}>
              <Typography variant={'h5'}>{t('website.profile.details')}</Typography>
            </Grid>

            <Grid container size={{ desktop: 6, tablet: 10 }} offset={{ mobile: 1 }} spacing={{ mobile: 2, tablet: 0 }} direction={{ mobile: 'column', tablet: 'row' }}>
              <Grid size={2}>
                <Typography variant={'h6'}>{t('website.profile.general')}</Typography>
              </Grid>
              <Grid container size={{ mobile: 12, tablet: 6 }} spacing={2}>
                <Grid size={{ mobile: 12, tablet: 6 }}>
                  <TextField fullWidth label={t('website.profile.name')} value={userName} variant="outlined" disabled />
                </Grid>
                <Grid size={{ mobile: 12, tablet: 6 }}>
                  <TextField fullWidth label={t('website.profile.surname')} value={userSurname} variant="outlined" disabled />
                </Grid>
                <Grid sx={{ ml: 1, mt: -1 }}>
                  <Typography variant={'caption'} sx={{ color: grey[500] }}>
                    {t('website.profile.caption')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container size={{ desktop: 6, tablet: 10 }} offset={{ tablet: 1 }} spacing={{ mobile: 2, tablet: 0 }} direction={{ mobile: 'column', tablet: 'row' }}>
              <Grid size={2}>
                <Typography variant={'h6'}>{t('website.profile.access')}</Typography>
              </Grid>
              <Grid container size={{ mobile: 12, tablet: 6 }} spacing={2} direction={'column'}>
                <Grid size={{ mobile: 12 }}>
                  <TextField fullWidth label={t('website.profile.email')} value={userEmail} variant="outlined" disabled />
                </Grid>
                <Grid container direction={'row'}>
                  <Grid>
                    <Button variant="outlined" size={'large'} sx={{ border: 1 }} disabled startIcon={<img src={GoogleIcon} alt="google icon" style={{ width: 24, height: 24 }} />}>
                      {t('website.profile.googleConnected')}
                    </Button>
                  </Grid>
                  <Grid>
                    <Button variant="outlined" size={'large'} onClick={() => handleLogoutClick()}>
                      {t('website.profile.logout')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container size={{ desktop: 6, tablet: 10 }} offset={{ tablet: 1 }} spacing={{ mobile: 2, tablet: 0 }} direction={{ mobile: 'column', tablet: 'row' }}>
              <Grid size={2}>
                <Typography variant={'h6'}>{t('website.profile.subscription')}</Typography>
              </Grid>
              <Grid size={{ mobile: 'grow' }} direction={'column'}>
                {isAppSumoUser ? <AppSumoSubscriptionView /> : <SubscriptionView />}
              </Grid>
            </Grid>
          </Grid>

          <Grid size={{ mobile: 12 }} direction={'column'} sx={{ minHeight: 300 }}>
            {!isAppSumoUser && <PricingPage />}
          </Grid>
        </>
      )}
    </Container>
  );
}
