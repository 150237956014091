import { FirebaseError } from "firebase/app";
import i18next from "i18next";
import { trackSentryError } from "./analytics";
import { API_BASE_URL } from "./const";
import {
  Creativity,
  Item,
  OutputLanguageData,
  RequestType,
  Version,
} from "./types";

export class ReqError extends Error {
  constructor(public message: string, public status: number) {
    super(message);
    this.name = "RequestError";
  }
}

export const STATUS_CODES = {
  BAD_REQUEST: 400,
  PAYMENT_REQUIRED: 402,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  CANT_SCRAPE_WEBSITE: 423,
};

export class ApiError extends Error {
  status: number;

  constructor(
    response: any,
    status: number,
    defaultMessage: string = i18next.t("app.errors.unexpectedError")
  ) {
    super(
      response?.data?.message ||
        response?.message ||
        response?.data?.error ||
        defaultMessage
    );
    this.status = status;
    this.name = "ApiError";
  }
}

interface ErrorHandlerOptions {
  error: unknown;
  onSetUpgradePopupOpen?: (isOpen: boolean) => void;
  onSetErrorMessage?: (message: string) => void;
  onSetErrorAlertOpen?: (isOpen: boolean) => void;
  onSetErrorButtonText?: (text: string) => void;
  onSetErrorAction?: (action: () => void) => void;
  onRetry?: () => void;
}

export function handleApiError({
  error,
  onSetUpgradePopupOpen,
  onSetErrorMessage,
  onSetErrorAlertOpen,
  onSetErrorButtonText,
  onSetErrorAction,
  onRetry,
}: ErrorHandlerOptions) {
  // Handle Firebase auth popup closed error
  if (
    error instanceof FirebaseError &&
    error?.code === "auth/popup-closed-by-user"
  ) {
    onSetErrorMessage?.(i18next.t("app.errors.signInCancelled"));
    onSetErrorAlertOpen?.(true);
    if (onSetErrorButtonText) {
      onSetErrorButtonText(i18next.t("app.buttons.retry"));
    }
    if (onSetErrorAction && onRetry) {
      onSetErrorAction(onRetry);
    }
    return;
  }

  // Handle bad url error
  if (
    error instanceof ApiError &&
    error.status === STATUS_CODES.NOT_FOUND &&
    error?.message?.toLowerCase().includes("valid") &&
    error?.message?.toLowerCase().includes("url")
  ) {
    onSetErrorMessage?.(i18next.t("app.errors.URLOrPromptRequired"));
    onSetErrorAlertOpen?.(true);
    return;
  }

  // Handle payment required error
  if (
    error instanceof ApiError &&
    error.status === STATUS_CODES.PAYMENT_REQUIRED
  ) {
    onSetUpgradePopupOpen?.(true);
    return;
  }

  // Handle cant scrape website error
  if (
    error instanceof ApiError &&
    error.status === STATUS_CODES.CANT_SCRAPE_WEBSITE
  ) {
    onSetErrorMessage?.(i18next.t("app.errors.cantScrapeWebsite"));
    onSetErrorAlertOpen?.(true);
    return;
  }

  // Handle other errors
  const errorMessage =
    error instanceof Error
      ? error.message
      : i18next.t("app.errors.unexpectedError");
  onSetErrorMessage?.(errorMessage);
  onSetErrorAlertOpen?.(true);
  if (onSetErrorButtonText) {
    onSetErrorButtonText(i18next.t("app.buttons.retry"));
  }
  if (onSetErrorAction && onRetry) {
    onSetErrorAction(onRetry);
  }
}

export async function register(userId: string) {
  try {
    const response = await fetch(`${API_BASE_URL}/register?user_id=${userId}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    trackSentryError(`Failed to register user`, {
      userId: userId,
      error: error,
    });
    throw error;
  }
}

export async function redeemAppsumoCode(
  userId: string | undefined,
  code: string
) {
  const response = await fetch(
    `${API_BASE_URL}/redeem_code?user_id=${userId}&code=${code}`
  );
  if (response.ok) {
    const jsonPayload = await response.json();
    const { is_success, code, redeemed_at, user_id } = jsonPayload.data;
    return { is_success, code, redeemed_at, user_id };
  } else {
    const errorText = await response.text();
    throw new Error(errorText);
  }
}

export async function createNewCampaign(
  userId: string,
  url: string,
  prompt: string,
  creativity: Creativity,
  outputLanguage: OutputLanguageData,
  session: string
): Promise<string> {
  try {
    if (!url?.length && !prompt?.length) {
      throw new Error(i18next.t("app.errors.URLOrPromptRequired"));
    }

    const response = await fetch(`${API_BASE_URL}/new_campaign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        url: url,
        prompt: prompt,
        creativity: creativity,
        language: outputLanguage.code,
        session: session,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new ApiError(
        errorData,
        response.status,
        i18next.t("app.errors.failedToCreateNewCampaign")
      );
    }

    const data = await response.json();
    return data.data.campaign.id;
  } catch (error) {
    if (error instanceof ApiError) {
      // Rethrow ApiError as is
      throw error;
    } else {
      // Handle unknown error types
      throw new ApiError(i18next.t("app.errors.unexpectedError"), 0);
    }
  }
}

export async function finalizeCampaignCreation(
  userId: string,
  campaignId: string,
  session: string
): Promise<string> {
  try {
    const response = await fetch(`${API_BASE_URL}/finalize_campaign_creation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        campaign_id: campaignId,
        session: session,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new ApiError(
        errorData,
        response.status,
        i18next.t("app.errors.failedToFinalizeCampaignCreation")
      );
    }

    const data = await response.json();
    return data.data.campaign.id;
  } catch (error) {
    if (error instanceof ApiError) {
      // Rethrow ApiError as is
      throw error;
    } else {
      // Handle unknown error types
      throw new ApiError(i18next.t("app.errors.unexpectedError"), 0);
    }
  }
}

export async function regenerateItem(
  userId: string,
  campaignId: string,
  itemId: string,
  itemVersion: number,
  creativity: Creativity,
  session: string,
  prompt: string
): Promise<{ new_version: Version; updated_item: Item }> {
  try {
    const response = await fetch(`${API_BASE_URL}/regenerate_item`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        campaign_id: campaignId,
        item_id: itemId,
        item_version: itemVersion.toString(),
        creativity: creativity,
        session: session,
        prompt: prompt,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new ApiError(
        errorData,
        response.status,
        i18next.t("app.errors.failedToRegenerateItem")
      );
    }

    const data = await response.json();
    return {
      new_version: data.data.new_version,
      updated_item: data.data.updated_item,
    };
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    } else {
      throw new ApiError(i18next.t("app.errors.unexpectedError"), 0);
    }
  }
}

export async function generateTab(
  userId: string,
  campaignId: string,
  session: string,
  items: RequestType[],
  remarks: string
): Promise<void> {
  try {
    const response = await fetch(`${API_BASE_URL}/generate_tab`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        campaign_id: campaignId,
        session: session,
        items: items,
        remarks: remarks,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new ApiError(
        errorData,
        response.status,
        i18next.t("app.errors.failedToGenerateTab")
      );
    }

    // The response is handled on the server-side, so we don't need to return anything here
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    } else {
      throw new ApiError(i18next.t("app.errors.unexpectedError"), 0);
    }
  }
}

export async function invalidateCredits(
  userId: string,
  session: string
): Promise<void> {
  try {
    const response = await fetch(`${API_BASE_URL}/invalidate_credits`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        session: session,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new ApiError(
        errorData,
        response.status,
        i18next.t("app.errors.failedToInvalidateCredits")
      );
    }

    // The response is handled on the server-side, so we don't need to return anything here
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    } else {
      throw new ApiError(i18next.t("app.errors.unexpectedError"), 0);
    }
  }
}
